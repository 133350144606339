import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { useAppContext } from 'src/common/context';
import { Layout } from 'src/components/Layout';
import { Button, Flex, GAP, Input, Subheading, MEDIA_QUERY } from 'src/ui';
import { apiCall, reportError } from 'src/common/utils';
import { SUPPORT_EMAIL, USER_PLAN } from 'src/common/config';

export const Profile = () => {
  const navigate = useNavigate();
  const { user, getUserData, setIsLoading, isLoading } = useAppContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const currentPlan = user.plan || {};

  const onSubmit = (formData) => {
    setIsLoading(true);

    apiCall({
      query: 'putProfile',
      variables: {
        ...formData,
        key: user.id, // key is only visible to Pro plans so we'll always pass it
      },
    })
      .then(() => {
        setIsLoading(false);
        getUserData();
      })
      .catch((error) => {
        reportError({ source: 'profile', error });
        setIsLoading(false);
      });
  };

  const backToDashboard = () => {
    navigate('/dashboard');
  };

  const goToSubscription = () => {
    navigate('/subscription');
  };

  // const deleteAccount = () => {
  //   const confirmedDelete = confirm(
  //     "Are you sure you want to delete your account and data? It can't be undone.",
  //   );
  //   if (!confirmedDelete) return;

  //   const confirmedEmail = confirm(
  //     'This will launch your default email client. Continue?',
  //   );
  //   if (!confirmedEmail) return;

  //   window.location = `mailto:${SUPPORT_EMAIL}?subject=Account Deletion Request`;
  // };

  // const requestData = () => {
  //   const confirmedEmail = confirm(
  //     'This will launch your default email client. Continue?',
  //   );
  //   if (!confirmedEmail) return;

  //   window.location = `mailto:${SUPPORT_EMAIL}?subject=Account Data Request`;
  // };

  const contactUs = () => {
    const confirmedEmail = confirm(
      'This will launch your default email client. Continue?',
    );
    if (!confirmedEmail) return;

    window.location = `mailto:${SUPPORT_EMAIL}`;
  };

  const menu = [
    // <Button
    //   key='deleteAccountButton'
    //   onClick={deleteAccount}
    //   aria-label='Delete your account and data'
    // >
    //   Delete Account
    // </Button>,
    // <Button
    //   key='requestDataButton'
    //   onClick={requestData}
    //   aria-label='Request data'
    // >
    //   Request Data
    // </Button>,
    <Button key='contactUsButton' onClick={contactUs} aria-label='Contact us'>
      Contact Us
    </Button>,
    <Button
      key='backToDashboardButton'
      onClick={backToDashboard}
      aria-label='Go back to the dashboard'
    >
      Dashboard
    </Button>,
  ];

  return (
    <Layout page={{ title: 'Profile', menu, isLoading }}>
      <Wrapper>
        <Container>
          <Subheading>Information</Subheading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormCols>
              <div>
                {/* {user.plan.id === USER_PLAN.pro.id && (
                  <Controller
                    control={control}
                    name='key'
                    render={({ field }) => (
                      <Input label='Key*' readOnly={true} {...field} />
                    )}
                    defaultValue={user.id}
                  />
                )} */}
                <Controller
                  control={control}
                  name='email'
                  render={({ field }) => (
                    <Input label='Email*' readOnly={true} {...field} />
                  )}
                  defaultValue={user.email}
                />
                <Controller
                  control={control}
                  name='fullname'
                  render={({ field }) => (
                    <Input
                      label='Full name*'
                      error={errors.fullname}
                      {...field}
                    />
                  )}
                  rules={{ required: 'Name is required' }}
                  defaultValue={user.fullname}
                  readOnly={true}
                />
                {/* <Controller
                  control={control}
                  name='city'
                  render={({ field }) => (
                    <Input label='City' error={errors.title} {...field} />
                  )}
                  defaultValue={user.city}
                />
                <Controller
                  control={control}
                  name='country'
                  render={({ field }) => <Input label='Country' {...field} />}
                  defaultValue={user.country}
                />
                <Controller
                  control={control}
                  name='language'
                  render={({ field }) => <Input label='Language' {...field} />}
                  defaultValue={user.language}
                /> */}
              </div>
            </FormCols>
            <Flex justifyContent='flex-end'>
              <Button type='submit' primary shadow>
                Update
              </Button>
            </Flex>
          </form>
        </Container>
        <Container>
          <Subheading>Current Plan</Subheading>
          <div>
            Status:{' '}
            <strong>
              {user.isEnabled && user.subscription?.isActive
                ? 'Active'
                : 'Canceled'}
            </strong>
          </div>
          {user.isEnabled && currentPlan && (
            <>
              <div>
                Name: <strong>{currentPlan.name}</strong>
              </div>
              <div>
                Rate: ${user.subscription.isActive ? currentPlan.rate : 0} per
                month
              </div>
              <br />
              <div>
                Features:
                <List>
                  <li>{currentPlan.pages} hosted pages with public links</li>
                  <li>{currentPlan.imagesPerPage} images per page</li>
                  <li>{currentPlan.hotspotsPerImage} hotspots per image</li>
                  {currentPlan.id !== USER_PLAN.pro.id ? (
                    <li>Support</li>
                  ) : (
                    <>
                      {/* <li>{currentPlan.embeds} custom website embeds</li>
                      <li>{currentPlan.imagesPerEmbed} images per embed</li> */}
                      {/* <li>
                        {currentPlan.embedRendersPerMonth.toLocaleString()}{' '}
                        impressions per month
                      </li> */}
                      <li>Priority support</li>
                    </>
                  )}
                </List>
              </div>
            </>
          )}
          {user.isEnabled && (
            <Flex justifyContent='flex-end'>
              <Button primary shadow onClick={goToSubscription}>
                Change
              </Button>
            </Flex>
          )}
        </Container>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: ${GAP.xxl};
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.sm};
  max-width: 1200px;

  ${MEDIA_QUERY.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${GAP.sm};
  width: 100%;
`;

const FormCols = styled.div`
  display: grid;
  grid-gap: ${GAP.md};
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;

  /* ${MEDIA_QUERY.sm} {
    grid-template-columns: 1fr 1fr;
  } */
`;

const List = styled.ul`
  li {
    margin-bottom: ${GAP.xs};
  }
`;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'src/components/Layout';
import { Button, GAP, Paragraph, Subheading } from 'src/ui';
import { hasBannerOk, sendEvent } from 'src/common/utils';
import { DataBanner } from 'src/components/DataBanner';
import { SITE_TITLE } from 'src/common/config';

export const Privacy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sendEvent({ label: 'pageview' });
  }, []);

  const backToHomepage = () => {
    navigate('/?utm=nav');
  };

  const getStarted = () => {
    navigate('/start');
  };

  const menu = [
    <Button
      key='startButton'
      onClick={getStarted}
      aria-label='Go to plans and get started'
    >
      Get Started
    </Button>,
    <Button
      key='backToHomepageButton'
      onClick={backToHomepage}
      aria-label='Go to the homepage'
    >
      Homepage
    </Button>,
  ];

  return (
    <Layout page={{ title: 'Privacy and Terms', menu }}>
      <Wrapper>
        <Subheading>Privacy</Subheading>
        <>
          <Paragraph>
            <strong>Last updated: June 23, 2023</strong>
          </Paragraph>
          <Paragraph>
            This privacy policy ("policy") will help you understand how{' '}
            {SITE_TITLE} Inc. ("{SITE_TITLE}", "us", "we", "our") uses and
            protects the data you provide to us when you visit and use the
            website ("website", "service").
          </Paragraph>
          <Paragraph>
            We reserve the right to change this policy at any given time, of
            which you will be promptly updated. If you want to make sure that
            you are up to date with the latest changes, we advise you to
            frequently visit this page.
          </Paragraph>

          <Paragraph className='heading'>What User Data We Collect</Paragraph>
          <ul>
            <li>
              Email (required): for account creation and communications such as
              notifications related to the service
            </li>
            <li>
              Full name (required): so we know what to call you, and verify
              billing
            </li>
            {/* <li>
              City (optional): we might use this information in the future to
              localize our services
            </li>
            <li>
              Country (optional): we might use this information in the future to
              localize our services
            </li>
            <li>
              Language (optional): we might use this information in the future
              to localize our services
            </li>
            <li>
              Payment method (required): we store any data required to process
              invoices
            </li> */}
          </ul>
          <Paragraph>
            <em>We never sell your personal information or data.</em>
          </Paragraph>

          <Paragraph className='heading'>
            Safequarding and Securing the Data
          </Paragraph>
          <Paragraph>
            {SITE_TITLE} is committed to securing your data and keeping it
            confidential, and has done all in its power to prevent data theft
            and unauthorized access by using managed services, which help us
            safeguard all the information we collect online. We use open-source
            code, sometimes vulnerabilities are discovered, we do our best to
            stay on top them by relying on third party tools. We also use third
            party vendors for things like payment processing, read the privacy
            policy and terms of service for the vendor of your choice on their
            website.
          </Paragraph>

          <Paragraph className='heading'>Cookies and Tracking</Paragraph>
          <Paragraph>
            We don't use cookies but we may collect data such as page views,
            clicks, etc. to help improve our services. Currently we collect data
            from non-registered visitors to measure our marketing efforts and
            conversions.
          </Paragraph>

          <Paragraph className='heading'>Links to Other Websites</Paragraph>
          <Paragraph>
            Our website may contain links that lead to other websites. If you
            click on these links, {SITE_TITLE} is not held responsible for your
            data and privacy protection. Visiting those websites is not governed
            by this privacy policy agreement. Make sure to read the privacy
            policy documentation of the website you go to from our website.
          </Paragraph>
        </>

        <Subheading id='terms' style={{ paddingTop: 200 }}>
          Terms
        </Subheading>
        <>
          <Paragraph>
            Please read these terms of service ("terms", "terms of service")
            carefully before using the {SITE_TITLE} website (the "service")
            operated by {SITE_TITLE} Inc. ("{SITE_TITLE}", "us", "we", "our").
          </Paragraph>

          <Paragraph className='heading'>Conditions of Use</Paragraph>
          <Paragraph>
            We provide services to you that are subject to the conditions stated
            below in this document. Every time you visit this website, use its
            services or make a purchase, you accept the following conditions.
            This is why we urge you to read them carefully.
          </Paragraph>

          <Paragraph className='heading'>Privacy Policy</Paragraph>
          <Paragraph>
            Before you continue using our website we advise you to read our
            privacy policy above regarding our user data collection. It will
            help you better understand our practices.
          </Paragraph>

          <Paragraph className='heading'>Copyright</Paragraph>
          <Paragraph>
            Content published on this website (digital downloads, images, texts,
            graphics, logos) is the property of {SITE_TITLE} and/or its content
            creators and protected by international copyright laws. The entire
            compilation of the content found on this website is the exclusive
            property of {SITE_TITLE}, with copyright authorship for this
            compilation by {SITE_TITLE}.
          </Paragraph>

          <Paragraph className='heading'>Communications</Paragraph>
          <Paragraph>
            The entire communication with us is electronic. Every time you send
            us an email or visit our website, you are going to be communicating
            with us. You hereby consent to receive communications from us. We
            will continue to communicate with you via notices on our website and
            by sending you emails. You also agree that all notices, disclosures,
            agreements, and other communications we provide to you
            electronically meet the legal requirements that such communications
            be in writing.
          </Paragraph>

          <Paragraph className='heading'>Applicable Law</Paragraph>
          <Paragraph>
            By visiting this website, you agree that the laws of Canada, without
            regard to principles of conflict laws, will govern these terms of
            service, or any dispute of any sort that might come between{' '}
            {SITE_TITLE} and you, or its business partners and associates.
          </Paragraph>

          <Paragraph className='heading'>Disputes</Paragraph>
          <Paragraph>
            Any dispute related in any way to your visit to this website or to
            products you purchase from us shall be arbitrated by provincial or
            federal court and you consent to exclusive jurisdiction and venue of
            such courts.
          </Paragraph>

          <Paragraph className='heading'>
            Comments, Reviews, and Emails
          </Paragraph>
          <Paragraph>
            Visitors may post content as long as it is not obscene, illegal,
            defamatory, threatening, infringing of intellectual property rights,
            invasive of privacy, or injurious in any other way to third parties.
            We reserve all rights (but not the obligation) to remove and/or edit
            such content. When you post your content, you grant {SITE_TITLE}{' '}
            non-exclusive, royalty-free and irrevocable right to use, reproduce,
            publish, modify such content throughout the world in any media.
          </Paragraph>

          <Paragraph className='heading'>License and Site Access</Paragraph>
          <Paragraph>
            We grant you a limited license to access and make personal and
            commercial use of this website. You are not allowed to download or
            modify it. This may be done only with written consent from us.
          </Paragraph>

          <Paragraph className='heading'>User Account</Paragraph>
          <Paragraph>
            If you are an owner of an account on this website, you are solely
            responsible for maintaining the confidentiality of your private user
            details (username and password). You are responsible for all
            activities that occur under your account or password.
          </Paragraph>
          <Paragraph>
            We reserve all rights to terminate accounts, edit or remove content
            and cancel orders at our sole discretion.
          </Paragraph>
        </>
      </Wrapper>
      {!hasBannerOk() && <DataBanner />}
    </Layout>
  );
};

const Wrapper = styled.div`
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.md};
  max-width: 1200px;

  p,
  ul {
    line-height: 2;
  }

  p.heading {
    font-weight: bold;
    margin-top: ${GAP.lg};
  }
`;

import { useCallback, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Draggable } from 'react-drag-reorder';
import styled from 'styled-components';
import { useAppContext } from 'src/common/context';
import { Layout } from 'src/components/Layout';
import { Button, COLOR, GAP, MEDIA_QUERY, Paragraph } from 'src/ui';
import { apiCall, reportError } from 'src/common/utils';
import ViewModal from 'src/components/ViewModal';
import { IS_PROD, MAIN_ROUTE, SAMPLE_IMAGE } from 'src/common/config';
import { Redirect } from 'src/App';

export const View = () => {
  const navigate = useNavigate();
  const { user, views, setViews, setIsLoading, isLoading, setModal } =
    useAppContext();
  const { viewId } = useParams();
  const view = views.find((e) => e.id === viewId);
  const [newOrder, setNewOrder] = useState(view.images);
  const [isReorder, setIsReorder] = useState(false);

  const goToDemo = () => {
    const goTo = view.isPage ? window.open : navigate;
    goTo(`/demo/${view.id}`);
  };

  const publishPage = () => {
    if (!view.isOnline) {
      alert(
        "Can't publish a page while it's offline. You can bring it online from the settings.",
      );
      return;
    }
    if (IS_PROD) {
      const isConfirmed = confirm(
        "Are you sure you want to publish the page? This can't be undone.",
      );
      if (!isConfirmed) return;
    }
    setIsLoading(true);
    apiCall({
      query: 'publishPage',
      variables: { ...view },
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        reportError({ source: 'publishPage', error });
      });
  };

  const backToDashboard = () => {
    navigate('/dashboard');
  };

  const saveView = useCallback(
    async (formData, skipLoading) => {
      if (!skipLoading) setIsLoading(true);

      const updatedView = {
        ...view,
        ...formData,
      };

      await apiCall({
        query: 'putView',
        variables: { ...updatedView },
      })
        .then(() => {
          const updatedViewIndex = views.findIndex(
            (e) => e.id === updatedView.id,
          );
          if (updatedView.pageId !== updatedView.username) {
            updatedView.pageId = updatedView.username;
          }
          setViews([
            ...views.slice(0, updatedViewIndex),
            updatedView,
            ...views.slice(updatedViewIndex + 1),
          ]);
          setModal(null);
          if (!skipLoading) setIsLoading(false);
        })
        .catch((error) => {
          reportError({ source: 'saveView', error });
          if (!skipLoading) setIsLoading(false);
        });
    },
    [setIsLoading, setModal, setViews, view, views],
  );

  const onSubmit = (formData) => {
    const {
      type: { value: type },
      scroll: scrollSelect,
      thumbnails: thumbnailsSelect,
      defaultIcons = view.isPage || undefined,
      ...restFormData
    } = formData;

    saveView({
      type,
      ...restFormData,
      ...(!!scrollSelect ? { scroll: scrollSelect.value } : {}),
      ...(!!thumbnailsSelect ? { thumbnails: thumbnailsSelect.value } : {}),
      defaultIcons,
    });
  };

  const openViewModal = () => {
    setModal({
      content: <ViewModal onSubmit={onSubmit} view={view} user={user} />,
    });
  };

  const deleteImage = (imageId) => (e) => {
    e.preventDefault();

    const confirmed = confirm(
      "Delete image and its hotspots? It can't be undone.",
    );
    if (!confirmed) return;

    saveView({
      images: [...view.images.filter((e) => e.id !== imageId)],
    });
  };

  const addImage = () => {
    saveView({
      images: [SAMPLE_IMAGE(), ...view.images],
    });
  };

  const reorder = (sourse, destination) => {
    const result = [...newOrder];
    const [removed] = result.splice(sourse, 1);
    result.splice(destination, 0, removed);
    setNewOrder(result);
  };

  const handleReorderToggle = () => {
    const newState = !isReorder;
    setIsReorder(newState);
    if (newState === false) {
      saveView({ ...view, images: newOrder }, true);
    }
  };

  const menu = [
    ...((view.isPage && view.images.length < user.plan.imagesPerPage) ||
    (!view.isPage && view.images.length < user.plan.imagesPerEmbed)
      ? [
          <Button
            key='addImageButton'
            onClick={addImage}
            aria-label='Add a new image to this view'
            disabled={isLoading || isReorder}
          >
            Add Image
          </Button>,
        ]
      : []),
    ...(view.images.length
      ? [
          <Button
            key='reorderImagesButton'
            onClick={handleReorderToggle}
            aria-label='Reorder images'
            disabled={isLoading}
          >
            Reorder
          </Button>,
        ]
      : []),
    ...(view.isPage
      ? [
          <Button
            key='publishPageButton'
            onClick={publishPage}
            aria-label='Publish your page'
            disabled={isLoading || isReorder}
          >
            Publish Page
          </Button>,
        ]
      : []),
    <Button
      key='demoViewButton'
      onClick={goToDemo}
      aria-label='Demo of your view'
      disabled={isLoading || isReorder}
    >
      Demo
    </Button>,
    <Button
      key='settingsButton'
      onClick={openViewModal}
      aria-label='Change view settings'
      disabled={isLoading || isReorder}
    >
      Settings
    </Button>,
    <Button
      key='backToDashboardButton'
      onClick={backToDashboard}
      aria-label='Go back to the dashboard'
      disabled={isLoading || isReorder}
    >
      Dashboard
    </Button>,
  ];

  if (!view.isEnabled) {
    alert('This view is disabled and can only be deleted.');
    return <Redirect to={MAIN_ROUTE} />;
  }

  return (
    <Layout page={{ title: view.title, menu, isLoading }}>
      <Container isReorder={isReorder}>
        {view.images.length === 0 && (
          <Paragraph>
            <Em>No images have been added.</Em>
          </Paragraph>
        )}
        {isReorder ? (
          <Draggable onPosChange={reorder}>
            {view.images.map((e, i) => (
              <ImageLink key={e.id} as='div' draggable={true} isReorder={true}>
                <img
                  src={e.src}
                  alt={e.description || view.title + ' slide ' + (i + 1)}
                />
              </ImageLink>
            ))}
          </Draggable>
        ) : (
          view.images.map((image, i) => (
            <ImageLink
              key={image.id}
              to={`/view/${view.id}/${image.id}`}
              draggable={false}
            >
              <img
                src={image.src}
                alt={image.description || view.title + ' slide ' + (i + 1)}
              />
              <button
                onClick={deleteImage(image.id)}
                aria-label={`Delete image at index ${i} from view`}
              >
                Delete
              </button>
            </ImageLink>
          ))
        )}
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${GAP.lg};
  margin: 0 auto;
  padding: ${GAP.md};
  width: 100%;
  max-width: 1200px;
  text-align: center;
  transition: 0.3s all;

  ${MEDIA_QUERY.md} {
    ${({ isReorder }) => (isReorder ? 'max-width: 500px;' : '')}
  }

  .grabbable {
    cursor: default;
  }
`;

const Em = styled.em`
  display: inline-block;
  margin-top: ${GAP.lg};
  color: ${COLOR.darkGray};
`;

const ImageLink = styled(Link)`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: ${GAP.sm};
  border: 1px dashed ${COLOR.black};
  border-radius: 4px;
  transition: 0.3s box-shadow;
  vertical-align: top;
  ${({ isReorder }) => (isReorder ? `padding: ${GAP.xs}; cursor: grab;` : '')}

  ${MEDIA_QUERY.sm} {
    margin: 0 0 0 auto;
    width: 60%;

    &:nth-child(even) {
      margin: 0 auto 0 0;
    }

    &:hover {
      border-style: solid;
      box-shadow: 0px 1px 3px ${COLOR.darkGray};

      button {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  img {
    width: 100%;
    margin: 0;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    color: ${COLOR.white};
    background-color: ${COLOR.black};
    border-top-right-radius: 4px;
    cursor: pointer;

    ${MEDIA_QUERY.md} {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

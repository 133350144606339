import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'src/components/Layout';
import { Button, COLOR, GAP, Subheading, TYPOGRAPHY } from 'src/ui';

export const UserDisabled = () => {
  const navigate = useNavigate();

  const menu = [
    <Button key='logoutButton' onClick={() => navigate('/signout')}>
      Signout
    </Button>,
  ];

  return (
    <Layout page={{ title: 'User Disabled', menu }}>
      <Wrapper>
        <Subheading>Your account has been disabled.</Subheading>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.sm};
  max-width: 1200px;

  p {
    font-size: ${TYPOGRAPHY.size.md};
    line-height: 2;
  }

  a {
    color: ${COLOR.black};
  }
`;

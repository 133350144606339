import { useEffect, useRef, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import {
  ContentState,
  Editor as RichTextEditor,
  EditorState as RichTextEditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML as richTextToHTML } from 'draft-js-export-html';
import { Sketch } from '@uiw/react-color';
import {
  Heading,
  Input,
  Button,
  Flex,
  GAP,
  Select,
  COLOR,
  Checkbox,
  Grid,
} from 'src/ui';
import { IS_PROD, SITE_URL, VIEW_TYPE, VIEW_TYPES } from 'src/common/config';
import { Label } from 'src/ui/Input';
import { apiCall, createIdfromUrl } from 'src/common/utils';

const MAX_DESC_LENGTH = 300;
const DESC_TOOLS = [
  {
    label: 'B',
    title: 'Bold',
    style: 'BOLD',
    method: 'inline',
  },
  {
    label: 'I',
    title: 'Italic',
    style: 'ITALIC',
    method: 'inline',
  },
  {
    label: 'U',
    title: 'Underline',
    style: 'UNDERLINE',
    method: 'inline',
  },
  {
    label: 'S',
    title: 'Strikethrough',
    style: 'STRIKETHROUGH',
    method: 'inline',
    separate: true,
  },
  // {
  //   label: 'highlight',
  //   style: 'HIGHLIGHT',
  //   method: 'inline',
  // },
  // {
  //   label: 'Sup',
  //   style: 'SUPERSCRIPT',
  //   method: 'inline',
  // },
  // {
  //   label: 'Sub',
  //   style: 'SUBSCRIPT',
  //   method: 'inline',
  //   separate: true,
  // },
  {
    label: 'List',
    style: 'unordered-list-item',
    method: 'block',
  },
  {
    label: 'Numbered List',
    style: 'ordered-list-item',
    method: 'block',
    // separate: true,
  },
  // {
  //   label: 'Blockquote',
  //   style: 'blockQuote',
  //   method: 'block',
  //   separate: true,
  // },
  // {
  //   label: 'Monospace',
  //   style: 'CODE',
  //   method: 'inline',
  // },
  // {
  //   label: 'Code Block',
  //   style: 'CODEBLOCK',
  //   method: 'inline',
  //   separate: true,
  // },
  // {
  //   label: 'Left',
  //   style: 'leftAlign',
  //   method: 'block',
  // },
  // {
  //   label: 'Center',
  //   style: 'centerAlign',
  //   method: 'block',
  // },
  // {
  //   label: 'Right',
  //   style: 'rightAlign',
  //   method: 'block',
  // },
  // { label: 'H1', style: 'header-one', method: 'block' },
  // { label: 'H2', style: 'header-two', method: 'block' },
  // { label: 'H3', style: 'header-three', method: 'block' },
  // { label: 'H4', style: 'header-four', method: 'block' },
  // { label: 'H5', style: 'header-five', method: 'block' },
  // { label: 'H6', style: 'header-six', method: 'block' },
];

// const invertHex = (hex) =>
//   '#' +
//   hex
//     .match(/[a-f0-9]{2}/gi)
//     .map((e) =>
//       ((255 - parseInt(e, 16)) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1'),
//     )
//     .join('');

const isLightOrDark = (color) => {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

const ViewModal = ({ onSubmit, closeModal, view, isNewPage }) => {
  const avatarFile = useRef();
  const [avatarFileName, setAvatarFileName] = useState();
  const [selectedAvatarImage, setSelectedAvatarImage] = useState();
  const [hex, setHex] = useState(view?.bgColor || '#ffffff');
  const [isBgHover, setIsBgHover] = useState(false);
  let richTextEditorInitState;
  if (view?.description) {
    const blocksFromHTML = convertFromHTML(view.description);
    richTextEditorInitState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
  }
  const [descEditorState, setDescEditorState] = useState(
    view?.description && richTextEditorInitState
      ? RichTextEditorState.createWithContent(richTextEditorInitState)
      : RichTextEditorState.createEmpty(),
  );

  const descEditorLength = descEditorState
    .getCurrentContent()
    .getPlainText('').length;
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const {
    fields: ctas,
    append,
    remove,
    replace,
  } = useFieldArray({
    control,
    name: 'ctas',
  });

  useEffect(() => {
    if (view?.isPage && view?.ctas) {
      replace(view.ctas);
    }
  }, [replace, view]);

  const pageUsername = watch('username');
  const typeField = watch('type');
  let currentType = view?.type || null;
  if (typeField) {
    if (typeof typeField === 'string') {
      currentType = typeField;
    } else {
      currentType = typeField.value;
    }
  }

  const pagePublicUrl = view?.isPage
    ? `${SITE_URL}/p/${view.pageId}/index.html`
    : '';
  const modalHeading = !!view
    ? view.isPage
      ? 'Page settings'
      : 'View settings'
    : isNewPage
    ? 'Create a new page'
    : 'Create a new view';
  const slideScrollOptions = [
    { value: 'snap', label: 'Snap' },
    { value: 'flow', label: 'Flow' },
  ];
  const slideThumbnailsOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const gridProps = {
    cols: '1fr 1fr',
    gap: GAP.sm,
  };

  const clearFile = () => {
    if (avatarFile && avatarFile.current) avatarFile.current.value = '';
    setAvatarFileName('');
    setSelectedAvatarImage(undefined);
  };

  const onAvatarSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 3 * 1048576) {
        alert('File should be smaller than 3 MB.');
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setValue('avatar', reader.result);
          setSelectedAvatarImage(reader.result);
          setAvatarFileName(file.name);
        } else {
          alert('File is too big.');
        }
      });
      reader.readAsDataURL(file);
    }
  };

  const handleBgMouseEnter = () => {
    setIsBgHover(true);
  };

  const handleBgMouseLeave = () => {
    setIsBgHover(false);
  };

  const handleRemoveLink = (index) => (e) => {
    e.preventDefault();
    if (IS_PROD) {
      const isConfirmed = confirm('Are you sure you want to remove this link?');
      if (!isConfirmed) return;
    }
    remove(index);
  };

  const formatBeforeSubmit = (formData) => {
    if (descEditorLength > MAX_DESC_LENGTH) {
      alert(`Description is over the ${MAX_DESC_LENGTH} character limit.`);
    } else {
      formData.description = richTextToHTML(
        descEditorState.getCurrentContent(),
      );
      formData.description =
        formData.description === '<p><br></p>' ? '' : formData.description;
      formData.ctas = formData.ctas.map((cta) => ({
        ...cta,
        id: createIdfromUrl(cta.url),
      }));
      if (formData.isOnline === undefined) {
        formData.isOnline = true;
      }
      formData.isPage = true;
      // formData.isPage = formData.isPage === 'true';
      onSubmit(formData);
    }
  };

  const applyRichTextStyle = (e, style, method) => {
    e.preventDefault();
    method === 'block'
      ? setDescEditorState(RichUtils.toggleBlockType(descEditorState, style))
      : setDescEditorState(RichUtils.toggleInlineStyle(descEditorState, style));
  };

  const isActive = (style, method) => {
    if (method === 'block') {
      const selection = descEditorState.getSelection();
      const blockType = descEditorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = descEditorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  const checkUsername = async (e) => {
    e.preventDefault();
    await apiCall({
      query: 'checkUsername',
      variables: { username: pageUsername },
    }).then((isAvailable) => {
      if (isAvailable) {
        alert('This ID is available!');
      } else {
        alert('This ID is NOT available. Try a different one.');
      }
    });
  };

  return (
    <Content isBgHover={isBgHover} bgColor={hex}>
      <Heading secondary>{modalHeading}</Heading>
      <form onSubmit={handleSubmit(formatBeforeSubmit)}>
        {!!view && (
          <Controller
            control={control}
            name='id'
            render={({ field }) => (
              <Input
                type={view.isPage ? 'hidden' : 'text'}
                {...(view.isPage ? {} : { label: 'ID for embedding' })}
                error={errors.id}
                readOnly={true}
                {...field}
              />
            )}
            rules={{ required: 'ID is required' }}
            defaultValue={view.id}
          />
        )}
        {/* <Controller
          control={control}
          name='isPage'
          render={({ field }) => (
            <Input type='hidden' readOnly={true} {...field} />
          )}
          rules={{ required: 'isPage is required' }}
          defaultValue={`${isNewPage || !!(view?.isPage)}`} // `false` boolean is considered empty
        /> */}
        {view?.isPage && (
          <>
            <Input
              label={
                <>
                  Public URL (
                  <a
                    href={pagePublicUrl}
                    target='blank'
                    style={{ color: COLOR.black }}
                  >
                    open in new tab
                  </a>
                  )
                </>
              }
              value={pagePublicUrl}
              readOnly={true}
            />
            <Controller
              control={control}
              name='isOnline'
              render={({ field }) => (
                <Checkbox
                  label='Online (publicly accessible)'
                  checked={view.isOnline}
                  {...field}
                />
              )}
            />
            <Hr />
            <Grid {...{ ...gridProps, cols: '1fr 200px' }}>
              <Controller
                control={control}
                name='username'
                render={({ field }) => (
                  <Input
                    label={
                      <>
                        Page ID
                        <Em>
                          (replace the random characters in the public URL with
                          something more memorable)
                        </Em>
                      </>
                    }
                    error={errors.username}
                    {...field}
                  />
                )}
                defaultValue={view?.pageId}
              />
              <Flex alignItems='center'>
                <Button onClick={checkUsername} shadow>
                  Check Availability
                </Button>
              </Flex>
            </Grid>
            <Hr />
            <Grid {...{ ...gridProps, cols: '1fr 1.5fr' }}>
              <div>
                <Controller
                  control={control}
                  name='avatar'
                  render={({ field }) => (
                    <Input type='hidden' readOnly={true} {...field} />
                  )}
                  defaultValue={view.avatar}
                />
                <Label>Avatar</Label>
                <File shadow aria-label='Select an image from your computer'>
                  Choose picture
                  <input
                    ref={avatarFile}
                    type='file'
                    accept='image/*'
                    onChange={onAvatarSelect}
                  />
                </File>
                {avatarFileName && <Button onClick={clearFile}>Reset</Button>}
                <Avatar
                  src={selectedAvatarImage || view.avatar}
                  alt='Current avatar'
                />
              </div>
              <BgColor>
                <Controller
                  control={control}
                  name='bgColor'
                  render={({ field }) => (
                    <Input type='hidden' readOnly={true} {...field} />
                  )}
                  defaultValue={view.bgColor}
                />
                <Controller
                  control={control}
                  name='txtColor'
                  render={({ field }) => (
                    <Input type='hidden' readOnly={true} {...field} />
                  )}
                  defaultValue={view.txtColor}
                />
                <Label>Background Colour</Label>
                <Sketch
                  onMouseEnter={handleBgMouseEnter}
                  onMouseLeave={handleBgMouseLeave}
                  disableAlpha={true}
                  presetColors={false}
                  color={hex}
                  onChange={(color) => {
                    setHex(color.hex);
                    setValue('bgColor', color.hex);
                    setValue(
                      'txtColor',
                      isLightOrDark(color.hex) === 'light'
                        ? COLOR.black
                        : COLOR.white,
                    );
                  }}
                />
              </BgColor>
            </Grid>
          </>
        )}
        <Controller
          control={control}
          name='title'
          render={({ field }) => (
            <Input label='Title*' error={errors.title} {...field} />
          )}
          rules={{ required: 'View title is required' }}
          defaultValue={view?.title}
        />
        {!!view && (
          <>
            <Label>
              <span style={{ marginRight: GAP.sm }}>
                Description ({descEditorLength}/{MAX_DESC_LENGTH})
              </span>
              {DESC_TOOLS.map((item, idx) => (
                <button
                  style={{
                    border: `1px solid #ccc`,
                    backgroundColor: 'transparent',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    color: isActive(item.style, item.method)
                      ? 'rgba(0, 0, 0, 1)'
                      : 'rgba(0, 0, 0, 0.5)',
                    marginRight: item.separate ? GAP.sm : 5,
                  }}
                  key={`${item.title || item.label}-${idx}`}
                  title={item.title}
                  onClick={(e) =>
                    applyRichTextStyle(e, item.style, item.method)
                  }
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {item.icon || item.label}
                </button>
              ))}
            </Label>
            <RichTextEditor
              editorState={descEditorState}
              onChange={setDescEditorState}
            />
          </>
        )}
        <Hr />
        <Controller
          control={control}
          name='type'
          render={({ field }) => (
            <Select
              label='Type*'
              options={VIEW_TYPES}
              error={errors.type}
              {...field}
            />
          )}
          rules={{ required: 'Type is required' }}
          defaultValue={
            !!view
              ? VIEW_TYPES.find((e) => e.value === view.type)
              : VIEW_TYPES[0]
          }
        />
        {!!view && currentType === VIEW_TYPE.slides && (
          <SlideOptions>
            <Controller
              control={control}
              name='scroll'
              render={({ field }) => (
                <Select
                  label='Scroll style*'
                  options={slideScrollOptions}
                  error={errors.scroll}
                  {...field}
                />
              )}
              rules={{ required: 'Scroll style is required' }}
              defaultValue={
                slideScrollOptions.find((e) => e.value === view.scroll) ||
                slideScrollOptions[1]
              }
            />
            <Controller
              control={control}
              name='thumbnails'
              render={({ field }) => (
                <Select
                  label='Show thumbnails*'
                  options={slideThumbnailsOptions}
                  error={errors.thumbnails}
                  {...field}
                />
              )}
              rules={{ required: 'Thumbnails selection is required' }}
              defaultValue={
                slideThumbnailsOptions.find(
                  (e) => e.value === view.thumbnails,
                ) || slideThumbnailsOptions[1]
              }
            />
          </SlideOptions>
        )}
        {view?.isPage && (
          <>
            <Hr />
            <Controller
              control={control}
              name='websiteUrl'
              render={({ field }) => (
                <Input label='Website Link' placeholder='https://' {...field} />
              )}
              defaultValue={view.websiteUrl}
            />
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='websiteText'
                render={({ field }) => (
                  <Input
                    label='Website Text'
                    placeholder='Eg: My Website'
                    {...field}
                  />
                )}
                defaultValue={view.websiteText}
              />
              <Controller
                control={control}
                name='websiteIcon'
                render={({ field }) => (
                  <Input
                    label='Website Icon'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.websiteIcon}
              />
            </Grid>
            <Hr style={{ marginTop: GAP.md }} />
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='youtube'
                render={({ field }) => (
                  <Input
                    label='YouTube Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.youtube}
              />
              <Controller
                control={control}
                name='facebook'
                render={({ field }) => (
                  <Input
                    label='Facebook Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.facebook}
              />
            </Grid>
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='instagram'
                render={({ field }) => (
                  <Input
                    label='Instagram Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.instagram}
              />
              <Controller
                control={control}
                name='twitter'
                render={({ field }) => (
                  <Input
                    label='Twitter Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.twitter}
              />
            </Grid>
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='tiktok'
                render={({ field }) => (
                  <Input
                    label='TikTok Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.tiktok}
              />
              <Controller
                control={control}
                name='discord'
                render={({ field }) => (
                  <Input
                    label='Discord Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.discord}
              />
            </Grid>
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='twitch'
                render={({ field }) => (
                  <Input
                    label='Twitch Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.twitch}
              />
              <Controller
                control={control}
                name='amazon'
                render={({ field }) => (
                  <Input
                    label='Amazon Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.amazon}
              />
            </Grid>
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='etsy'
                render={({ field }) => (
                  <Input label='Etsy Link' placeholder='https://' {...field} />
                )}
                defaultValue={view.etsy}
              />
              <Controller
                control={control}
                name='ltk'
                render={({ field }) => (
                  <Input label='LTK Link' placeholder='https://' {...field} />
                )}
                defaultValue={view.ltk}
              />
            </Grid>
            <Grid {...gridProps}>
              <Controller
                control={control}
                name='poshmark'
                render={({ field }) => (
                  <Input
                    label='Poshmark Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.poshmark}
              />
              <Controller
                control={control}
                name='pinterest'
                render={({ field }) => (
                  <Input
                    label='Pinterest Link'
                    placeholder='https://'
                    {...field}
                  />
                )}
                defaultValue={view.pinterest}
              />
            </Grid>
            <Controller
              control={control}
              name='defaultIcons'
              render={({ field }) => (
                <Checkbox
                  label='Use Default Icons (uncheck for minimal icons)'
                  checked={
                    view.defaultIcons === undefined ? true : view.defaultIcons
                  }
                  {...field}
                />
              )}
            />
            <Hr />
            {ctas.map((cta, index) => (
              <Grid key={cta.id} {...gridProps} cols='1fr 1fr 30px'>
                <Controller
                  control={control}
                  name={`ctas.${index}.label`}
                  render={({ field }) => (
                    <Input
                      label={`Custom Link ${index + 1}`}
                      placeholder='Eg: Click Here'
                      {...field}
                    />
                  )}
                  defaultValue={cta.label}
                />
                <Controller
                  control={control}
                  name={`ctas.${index}.url`}
                  render={({ field }) => (
                    <Input
                      isLabelSpace={true}
                      placeholder='https://'
                      {...field}
                    />
                  )}
                  defaultValue={cta.url}
                />
                <Button
                  onClick={handleRemoveLink(index)}
                  style={{ padding: 0, height: 30, marginTop: 25 }}
                >
                  &times;
                </Button>
              </Grid>
            ))}
            {ctas.length < 10 && (
              <Button onClick={() => append()} shadow>
                Add custom link
              </Button>
            )}
          </>
        )}
        <Flex justifyContent='flex-end' margin={`${GAP.lg} 0 0 0`}>
          <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0`} shadow>
            Cancel
          </Button>
          <Button type='submit' primary shadow>
            {!!view ? 'SAVE' : 'CREATE'}
          </Button>
        </Flex>
      </form>
    </Content>
  );
};

const Content = styled.div`
  position: relative;
  margin: 0;
  padding: ${GAP.md};
  transition: background-color 0.3s;

  ${({ isBgHover, bgColor }) =>
    isBgHover
      ? `
        background-color: ${bgColor};

        h1,
        ${Hr},
        input,
        label,
        button,
        span,
        .DraftEditor-root,
        & > * > * > * > *:not(.w-color-sketch) {
          opacity: 0;
        }

        .w-color-sketch, .w-color-sketch * {
          opacity: 1 !important;
        }
      `
      : ''}

  .DraftEditor-root {
    margin: 0 0 ${GAP.md} 0;
    padding: ${GAP.xs};
    border: 1px solid ${COLOR.black};
    border-radius: 4px;
    transition: 0.3s border-color;

    &:focus-within {
      border-color: ${COLOR.green};
    }
  }
`;

const BgColor = styled.div`
  width: 20%;
  margin-left: 30%;

  span {
    white-space: nowrap;
  }

  .w-color-sketch {
    transform: scale(0.8);
    transform-origin: top left;
    margin-bottom: -30px;
  }
`;

const SlideOptions = styled.div`
  margin: 0 0 ${GAP.md} 0;
  padding: ${GAP.sm};
  background-color: ${COLOR.lightGray};

  label:last-child {
    margin-bottom: ${GAP.xs};
  }
`;

const Hr = styled.div`
  margin: ${GAP.lg} auto;
  padding: 0;
  width: 80%;
  height: 0;
  border: 0;
  border-top: 1px dashed ${COLOR.black};
`;

const Em = styled.em`
  margin-left: ${GAP.xs};
  color: ${COLOR.darkGray};
  font-size: 12px;
`;

const File = styled(Button)`
  position: relative;
  margin: 0 ${GAP.xs} ${GAP.xs} 0;
  padding: ${GAP.xs} ${GAP.sm};

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Avatar = styled.img`
  display: block;
  margin: ${GAP.sm} 0;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
`;

export default ViewModal;

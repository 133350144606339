import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from 'src/components/Header';
import Modal from 'src/components/Modal';
import { COLOR, Loader } from 'src/ui';

export const Layout = ({ children, page, isSSR }) => {
  const hasTransition = !(isSSR || (page && page.transition === false));
  const [transition, setTransition] = useState(!hasTransition);
  const transitionProps = transition ? {} : { className: 'init' };

  useEffect(() => {
    if (hasTransition) {
      setTransition(true);
    }
  }, [hasTransition]);

  return (
    <>
      <Viewport id='viewport' {...(page ? { bgColor: page.bgColor } : {})}>
        <Header id='header' page={page} />
        {page && page.isLoading ? (
          <Loader big />
        ) : (
          <Main {...transitionProps}>{children}</Main>
        )}
      </Viewport>
      {!isSSR && <Modal />}
    </>
  );
};

export const viewportClass = 'unfocus';
const Viewport = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ bgColor }) => bgColor || COLOR.white};
  transition: all 0.5s;

  &.${viewportClass} {
    transform: scale(0.85);
    filter: blur(4px);
  }
`;

const Main = styled.main`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  transition: 0.3s opacity ease-in, 0.5s transform ease-out;

  &.init {
    pointer-events: none;
    opacity: 0;
    transform: translate3d(0, 70px, 0);
  }
`;

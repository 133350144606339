import ReactDOM from 'react-dom';
import App from 'src/App';
import { IS_PROD } from 'src/common/config';
import { reportError } from 'src/common/utils';

ReactDOM.render(<App />, document.getElementById('app'));

if (IS_PROD && 'serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .catch((error) => reportError({ source: 'sw', error }));
}

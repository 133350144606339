import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'src/components/Layout';
import { Button, COLOR, GAP, TYPOGRAPHY } from 'src/ui';

const BLOG_POSTS = [
  {
    id: 'how-to-build-an-independent-personal-brand-as-an-influencer',
    title: 'How to Build an Independent Personal Brand as an Influencer',
  },
  {
    id: 'how-to-boost-your-affiliate-marketing-with-image-hotspots',
    title: 'How to Boost Your Affiliate Marketing with Image Hotspots',
  },
  {
    id: 'why-traditional-marketing-and-branding-are-dead',
    title:
      'Why Traditional Marketing and Branding Are Dead (and What to Do Instead)',
  },
  {
    id: 'how-to-make-money-with-affiliate-marketing-in-2023',
    title: 'How to Make Money with Affiliate Marketing in 2023',
  },
  {
    id: 'why-influencer-marketing-beats-traditional-marketing',
    title: 'Why Influencer Marketing Beats Traditional Marketing',
  },
  {
    id: 'how-to-promote-your-social-media-as-an-influencer',
    title: 'How to Promote Your Social Media as an Influencer',
  },
  {
    id: 'why-you-should-use-visuals-and-images-instead-of-text',
    title: 'Why You Should Use Visuals and Images Instead of Text',
  },
];

export const Blog = () => {
  const navigate = useNavigate();

  const menu = [
    <Button
      key='startButton'
      onClick={() => navigate('/start')}
      aria-label='Get started'
    >
      Get Started
    </Button>,
    <Button
      key='homeButton'
      onClick={() => navigate('/')}
      aria-label='Go to homepage'
    >
      Home
    </Button>,
  ];

  return (
    <Layout page={{ title: 'Blog', menu }}>
      <Wrapper>
        <ul>
          {BLOG_POSTS.map(({ id, title }) => (
            <li>
              <span>&rarr;</span>
              <a href={`/blog/${id}`}>{title}</a>
            </li>
          ))}
        </ul>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.sm};
  max-width: 1200px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0 0 ${GAP.md} 0;
      padding: 0;
      font-size: ${TYPOGRAPHY.size.md};

      span {
        opacity: 0;
        transition: 0.3s opacity;

        &:has(~ a:hover) {
          opacity: 1;
        }
      }

      a {
        display: inline-block;
        margin: 0;
        padding: ${GAP.sm};
        color: ${COLOR.black};
        transform: translateX(-40px);
        transition: 0.3s transform;

        &:hover {
          transform: translateX(0);
        }
      }
    }
  }
`;

import { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import cn from 'classnames';
import { useElementOnScreen } from 'src/common/utils';

const animationDuration = 1;
const animationEffect = 'cubic-bezier(0.215, 0.61, 0.355, 1)';

export const BounceIn = ({ topMargin, children }) => {
  const ref = useRef(null);
  const onScreen = useElementOnScreen({
    ref,
    topMargin,
  });

  return (
    <Wrapper
      ref={ref}
      className={cn({
        bounceIn: onScreen,
      })}
    >
      {children}
    </Wrapper>
  );
};

const bounceIn = keyframes`
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: ${animationEffect};
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  opacity: 0;
  transform: scale3d(0.3, 0.3, 0.3);

  &.bounceIn {
    animation-name: ${bounceIn};
    animation-duration: ${animationDuration}s;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

// keep `React` for script.js (embed.js)
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ImageMap } from 'src/components/ImageMap';
import { GAP, COLOR, MEDIA_QUERY } from 'src/ui';
import { VIEW_TYPE } from 'src/common/config';

export const maxNonLazyImages = 6;

export const Embed = ({ view }) => {
  const { type, images = [], scroll, thumbnails } = view;

  useEffect(() => {
    const lazyImages = document.querySelectorAll('.sl-embed img[data-src]');
    if (images?.length > maxNonLazyImages && lazyImages?.length) {
      if ('IntersectionObserver' in window) {
        const imageObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const image = entry.target;
              image.src = image.dataset.src;
              delete image.dataset.src;
              imageObserver.unobserve(image);
            }
          });
        });

        lazyImages.forEach((image) => {
          imageObserver.observe(image);
        });

        return () => {
          lazyImages.forEach((image) => {
            imageObserver.unobserve(image);
          });
        };
      } else {
        let lazyloadThrottleTimeout;

        const lazyload = () => {
          if (lazyloadThrottleTimeout) {
            clearTimeout(lazyloadThrottleTimeout);
          }
          lazyloadThrottleTimeout = setTimeout(() => {
            lazyImages.forEach((image) => {
              if (image.offsetTop < window.innerHeight + window.scrollY) {
                image.src = image.dataset.src;
                delete image.dataset.src;
              }
            });
            if (lazyImages.length === 0) {
              document.removeEventListener('scroll', lazyload);
              window.removeEventListener('resize', lazyload);
              window.removeEventListener('orientationChange', lazyload);
            }
          }, 20);
        };

        document.addEventListener('scroll', lazyload);
        window.addEventListener('resize', lazyload);
        window.addEventListener('orientationChange', lazyload);

        return () => {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        };
      }
    }
  }, [images]);

  return (
    <Container className={`sl-embed ${type} ${scroll}`}>
      <Images>
        {images.map((e, i) => (
          <ImageMapContainer key={e.id}>
            <ImageMap
              id={e.id}
              src={e.src}
              maps={e.maps}
              alt={e.caption}
              isLazy={i + 1 > maxNonLazyImages}
            />
          </ImageMapContainer>
        ))}
      </Images>
      {type === VIEW_TYPE.slides && thumbnails && (
        <Thumbnails>
          {images.map((e) => (
            <a key={`${e.id}-thumb`} href={`#${e.id}`}>
              <img src={e.src} alt={'Slideshow thumbnail'} draggable={false} />
            </a>
          ))}
        </Thumbnails>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const scrollBg = `${COLOR.gray}70`;
const scrollColor = COLOR.black;

const Images = styled.div`
  margin: 0;
  padding: 0;

  .sl-embed.slides & {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    /* Works on Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${scrollColor} ${scrollBg};
    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      background: ${scrollBg};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${scrollColor};
      /* border-radius: 20px; */
      /* border: 3px solid ${scrollBg}; */
    }
  }

  .sl-embed.tiles & {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    ${MEDIA_QUERY.sm} {
      grid-gap: ${GAP.md};
    }
  }
`;

const ImageMapContainer = styled.div`
  margin: 0;
  padding: 0;

  .sl-embed.slides & {
    min-width: 100%;
  }

  .sl-embed.slides.snap & {
    scroll-snap-align: start;
  }
`;

const Thumbnails = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;

  a {
    display: inline-block;
    margin: ${GAP.sm};
    padding: 0;

    img {
      display: inline-block;
      padding: 0;
      padding: 0;
      width: 30px;
    }
  }
`;

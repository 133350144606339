import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from 'src/common/context';
import { Layout } from 'src/components/Layout';
import { ImageEditor } from 'src/components/ImageEditor';
import { Button, GAP } from 'src/ui';
import { apiCall, reportError } from 'src/common/utils';
import { Redirect } from 'src/App';
import { MAIN_ROUTE } from 'src/common/config';

export const Image = () => {
  const navigate = useNavigate();
  const { views, setViews, setIsLoading, isLoading } = useAppContext();
  const { viewId, imageId } = useParams();
  const view = views.find((e) => e.id === viewId);
  const image = view.images.find((e) => e.id === imageId);

  useEffect(() => {
    if (!image) navigate(`/view/${viewId}`);
  }, [navigate, image, viewId]);

  const saveImage = async (updatedImage) => {
    setIsLoading(true);

    const updatedImageIndex = view.images.findIndex(
      (e) => e.id === updatedImage.id,
    );

    const updatedView = {
      ...view,
      images: [
        ...view.images.slice(0, updatedImageIndex),
        updatedImage,
        ...view.images.slice(updatedImageIndex + 1),
      ],
    };

    await apiCall({
      query: 'putView',
      variables: {
        ...updatedView,
        isImageUpdate: true,
      },
    })
      .then(() => {
        const updatedViewIndex = views.findIndex(
          (e) => e.id === updatedView.id,
        );
        setViews([
          ...views.slice(0, updatedViewIndex),
          updatedView,
          ...views.slice(updatedViewIndex + 1),
        ]);
        setIsLoading(false);
      })
      .catch((error) => {
        reportError({ source: 'saveImage', error });
        setIsLoading(false);
      });
  };

  const backToView = () => {
    navigate(`/view/${viewId}`);
  };

  const menu = [
    <Button
      key='backToViewButton'
      onClick={backToView}
      aria-label='Go back to the view'
    >
      Back to View
    </Button>,
  ];

  if (!view.isEnabled) {
    alert('This view is disabled and can only be deleted.');
    return <Redirect to={MAIN_ROUTE} />;
  }

  return (
    <Layout page={{ title: 'Edit image', menu, isLoading }}>
      <Container>
        {view && image ? (
          <ImageEditor
            id={image.id}
            src={image.src}
            maps={image.maps}
            onSave={saveImage}
          />
        ) : null}
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  margin: 0 auto;
  padding: ${GAP.md};
  max-width: 800px;
`;

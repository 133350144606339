import { cloneElement, useEffect } from 'react';
import styled from 'styled-components';
import { useAppContext } from 'src/common/context';
import { COLOR, MEDIA_QUERY } from 'src/ui';
import { viewportClass } from 'src/components/Layout';
import { useCallback } from 'react';

const Modal = () => {
  const { modal, setModal } = useAppContext();
  const isOpen = !!modal;

  const closeModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  useEffect(() => {
    const viewport = document.getElementById('viewport');
    if (viewport) {
      if (modal) {
        viewport.classList.add(viewportClass);
      } else {
        viewport.classList.remove(viewportClass);
      }
    }
  }, [modal]);

  useEffect(() => {
    const handleEsc = (e) => {
      const isEsc = e.key === 'Escape';
      if (isEsc) closeModal();
    };

    document.addEventListener('keypress', handleEsc);

    return () => {
      document.removeEventListener('keypress', handleEsc);
    };
  }, [closeModal]);

  return (
    <>
      <Backdrop isOpen={isOpen} onClick={closeModal} />
      <Content isOpen={isOpen}>
        {modal &&
          !!modal.content &&
          cloneElement(modal.content, { closeModal })}
      </Content>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s opacity;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  overflow: hidden;
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 85vw;
  height: 100vh;
  background-color: ${COLOR.white};
  transition: 0.5s transform cubic-bezier(0.77, 0.2, 0.05, 1);
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '110%')});
  overflow-x: hidden;
  overflow-y: auto;

  ${MEDIA_QUERY.sm} {
    width: 50vw;
  }
`;

export default Modal;

import { useState, useEffect, useRef, useContext, useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button, COLOR, GAP, Input, Paragraph } from 'src/ui';
import { IS_PROD, ALLOWED_IMAGES } from 'src/common/config';
import { AppContext } from 'src/common/context';
import { isMediaFileUrl } from 'src/common/utils';
import { ImageMap } from 'src/components/ImageMap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const confirmMessage =
  "Current hotspots will be lost and it can't be undone. Continue?";

// const initCrop = {
//   unit: '%',
//   x: 40,
//   y: 40,
//   height: 20,
//   width: 20,
// };

export const ImageEditor = ({ id, onSave: onSaveProp, src, maps }) => {
  const { user } = useContext(AppContext);
  const file = useRef();
  const [img, setImg] = useState(src);
  const [mapArea, setMapArea] = useState(maps);
  const [crop, setCrop] = useState();
  const [cropRotation, setCropRotation] = useState('0deg');
  const [isCropLocked, setIsCropLocked] = useState(false);
  const initialMousePositionRef = useRef({ x: 0, y: 0 });

  const onSave = () => {
    onSaveProp({ id, src: img, maps: mapArea });
  };

  const clearFile = () => {
    if (file && file.current) file.current.value = '';
  };

  const onRotationHandleMouseMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const angle =
      (Math.atan2(
        e.clientY - window.innerHeight / 2,
        e.clientX - window.innerWidth / 2,
      ) *
        180) /
        Math.PI +
      180;
    const angleCss = `${angle}deg`;
    setCropRotation(angleCss);
    const cropArea = document.querySelector('.ReactCrop__crop-selection');
    cropArea.style.transform = `rotate(${angleCss})`;
  };

  const onRotationHandleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    initialMousePositionRef.current = { x: e.clientX, y: e.clientY };
    document.addEventListener('mousemove', onRotationHandleMouseMove);
    document.addEventListener('mouseup', onRotationHandleMouseUp);
  };

  const onRotationHandleMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener('mousemove', onRotationHandleMouseMove);
    document.removeEventListener('mouseup', onRotationHandleMouseUp);
  };

  useEffect(() => {
    const cropBoxEle = document.querySelector('.ReactCrop');
    const handle = (e) => {
      const cropEle = document.querySelector('.ReactCrop__crop-selection');
      if (e.target === cropEle) {
        addSubArea('add')();
      }
    };
    if (cropBoxEle) {
      cropBoxEle.addEventListener('dblclick', handle);
      return () => {
        cropBoxEle.removeEventListener('dblclick', handle);
      };
    }
  });

  const onFileClick = (e) => {
    if (IS_PROD) {
      const isConfirmed = confirm(confirmMessage);
      if (!isConfirmed) {
        e.preventDefault();
        return;
      }
    }
    clearFile();
  };

  const onFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 3 * 1048576) {
        alert('File should be smaller than 3 MB.');
        return;
      }
      if (IS_PROD) {
        const isConfirmed = confirm(confirmMessage);
        if (!isConfirmed) return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImg(reader.result);
          setMapArea([]);
          setCrop();
        } else {
          alert('File is too big.');
        }
      });
      reader.readAsDataURL(file);
    }
  };

  const onLinkSelect = () => {
    if (IS_PROD) {
      const isConfirmed = confirm(confirmMessage);
      if (!isConfirmed) return;
    }
    const url = prompt('Please enter image URL:');
    if (!url) return;
    if (!isMediaFileUrl(url)) {
      alert('Invalid image link. Please try a different one.');
      return;
    }
    setImg(url);
    setMapArea([]);
    setCrop();
    clearFile();
  };

  const setMap = (type, index) => (e) => {
    const { value } = e.target;
    const mapAreaNew = mapArea.map((map, idx) =>
      index === idx ? { ...map, [type]: value } : map,
    );
    setMapArea(mapAreaNew);
  };

  const addSubArea =
    (type, index = 0) =>
    () => {
      let newArea = {};
      let mapAreaNew = [];
      if (type === 'add') {
        if (mapArea.length === user.plan.hotspotsPerImage) {
          alert("You've reached the maximum number of hotspots.");
          return;
        }
        const { x, y, width, height } = crop;
        newArea = {
          id: uuidv4(),
          width: `${width}%`,
          height: `${height}%`,
          left: `${x}%`,
          top: `${y}%`,
          rotation: cropRotation,
        };
        mapAreaNew = [...mapArea, newArea];
      } else {
        if (IS_PROD) {
          const isConfirmed = confirm("Remove hotspot? It can't be undone.");
          if (!isConfirmed) return;
        }
        mapAreaNew = [...mapArea.slice(0, index), ...mapArea.slice(index + 1)];
      }
      setMapArea(mapAreaNew);
      setCropRotation('0deg');
      const cropArea = document.querySelector('.ReactCrop__crop-selection');
      cropArea.style.transform = `rotate(0deg)`;
    };

  const onCropChange = (crop, percentCrop) => {
    setCrop(percentCrop);
  };

  // const toSetMap = (e) => {
  //   const value = e.target.value;
  //   let result = [];
  //   try {
  //     result = JSON.parse(value);
  //     setMapArea(result);
  //     console.log('success');
  //   } catch (error) {
  //     reportError({ source: 'toSetMap', error });
  //   }
  // };

  const ImageMapComponent = useMemo(
    () => <ImageMap id={id} src={img} maps={mapArea} isEditor={true} />,
    [id, mapArea, img],
  );

  return (
    <>
      <Styles />
      <div className='images-map-content'>
        <Paragraph margin={`0 0 ${GAP.md} 0`}>
          Select an area of the image on the left as hotspot then double-click
          it to add.
        </Paragraph>
        <div className='crop-box'>
          <div className='map-box'>
            <div className='map-box-img'>
              <ReactCrop
                crop={crop}
                onChange={onCropChange}
                ruleOfThirds
                disabled={isCropLocked}
                renderSelectionAddon={() => (
                  <RotationHandle
                    onMouseDown={onRotationHandleMouseDown}
                    onMouseEnter={() => setIsCropLocked(true)}
                    onMouseLeave={() => setIsCropLocked(false)}
                  />
                )}
              >
                <img src={img} alt='Map selection' draggable={false} />
              </ReactCrop>
              {img &&
                mapArea?.map((map, index) => {
                  const { top, left, width, height, rotation } = map;
                  return (
                    <span
                      className='crop-item'
                      key={index}
                      style={{
                        top,
                        left,
                        width,
                        height,
                        ...(rotation
                          ? { transform: `rotate(${rotation})` }
                          : {}),
                      }}
                    />
                  );
                })}
            </div>
            <div className='map-box-img'>{ImageMapComponent}</div>
          </div>
        </div>
        <div className='maps-area'>
          {!mapArea?.length && (
            <Paragraph>
              No hotspots yet. Select an area from the image on the left, and
              double click it to add hotspot.
            </Paragraph>
          )}
          {img &&
            mapArea?.map((map, index) => {
              return (
                <div className='map-area' key={index}>
                  <label className='title'>
                    Spott <span>{index + 1}</span> Link:
                  </label>
                  <div className='setting-box'>
                    {/* <div className='setting-box-item'>
                      <label>width: </label>
                      <input
                        value={parseFloat(map.width)}
                        type='range'
                        min='0'
                        max='100'
                        onChange={setMap('width', index)}
                      />
                    </div>
                    <div className='setting-box-item'>
                      <label>height: </label>
                      <input
                        value={parseFloat(map.height)}
                        type='range'
                        min='0'
                        max='100'
                        onChange={setMap('height', index)}
                      />
                    </div>
                    <div className='setting-box-item'>
                      <label>left: </label>
                      <input
                        value={parseFloat(map.left)}
                        type='range'
                        min='0'
                        max='100'
                        onChange={setMap('left', index)}
                      />
                    </div>
                    <div className='setting-box-item'>
                      <label>top: </label>
                      <input
                        value={parseFloat(map.top)}
                        type='range'
                        min='0'
                        max='100'
                        onChange={setMap('top', index)}
                      />
                    </div> */}
                    <label className='setting-box-item'>
                      {/* <span>Link:</span> */}
                      <Input
                        type='url'
                        value={map.link}
                        placeholder='https://'
                        onChange={setMap('link', index)}
                      />
                    </label>
                    <label className='setting-box-item'>
                      {/* <span>Label:</span> */}
                      <Input
                        type='text'
                        value={map.label}
                        placeholder='optional label'
                        onChange={setMap('label', index)}
                      />
                    </label>
                  </div>
                  <Button
                    className='remove-button'
                    onClick={addSubArea('sub', index)}
                    aria-label='Remove hotspot from image'
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
        </div>
        <div className='opt-box'>
          <div>
            {/* <Button shadow className='opt-box-btn' onClick={addSubArea('add')}>
              Add map
            </Button> */}
            <Button
              shadow
              className='opt-box-btn'
              aria-label='Select an image from your computer'
            >
              <input
                ref={file}
                type='file'
                accept={ALLOWED_IMAGES.split('|')
                  .map((e) => `.${e}`)
                  .join(',')}
                className='picker-image'
                onChange={onFileSelect}
                onClick={onFileClick}
              />
              Choose image
            </Button>
            <Button
              shadow
              className='opt-box-btn'
              onClick={onLinkSelect}
              aria-label='Select and image by entering its online URL'
            >
              Link image
            </Button>
          </div>
          <div>
            <Button
              shadow
              primary
              className='opt-box-btn'
              onClick={onSave}
              aria-label='Save changes to image hotspots'
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const Styles = createGlobalStyle`
  .images-map-content {
    img {
      width: 100%;
    }

    .crop-box {
      border: 1px dashed #000;
      border-radius: 4px;
      padding: ${GAP.sm};
      display: flex;

      .ReactCrop {
        position: relative;
        display: block;

        .ReactCrop__image {
          opacity: 1 !important;
        }

        .ReactCrop__crop-selection {
          box-shadow: inset 0 0 0 9999em rgba(0, 0, 0, 0.5) !important;
          z-index: 99;
        }
      }
    }

    .map-box {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: top;
      justify-content: center;

      .map-box-img {
        width: 100%;
        position: relative;
        overflow: hidden;

        &:first-child{
          margin-right: ${GAP.sm};
        }

        .crop-item {
          position: absolute;
          z-index: 10;
          border: 1px solid;
          border-image-source: url('data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==');
          border-image-slice: 1;
          border-image-repeat: repeat;

          &::before {
            content: '';
            box-shadow: inset 0 0 0 9999em rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
          }
        }
      }
    }

    .maps-area {
      display: grid;
      grid-template-rows: auto;
      grid-gap: ${GAP.md};
      border-radius: 4px;
      background-color: ${COLOR.lightGray};
      margin: ${GAP.lg} 0 ${GAP.sm} 0;
      padding: ${GAP.sm};
    }

    .map-area {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-gap: ${GAP.sm};
      align-items: center;
      box-sizing: border-box;

      .title {
        span {
          display: inline-block;
          width: 20px;
          line-height: 20px;
          background-color: ${COLOR.black};
          color: ${COLOR.green};
          border-radius: 50%;
          text-align: center;
        }
      }

      .setting-box {
        display: grid;
        grid-template-columns: auto 150px;
        grid-gap: ${GAP.xxs};

        .setting-box-item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;

          label {
            flex-grow: 1;
          }
        }
      }

      .remove-button {
        padding: ${GAP.xs};
        border: 0;
        color: ${COLOR.black};
        background-color: transparent;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          background-color: ${COLOR.white};
          text-decoration: none;
        }
      }
    }

    .opt-box {
      display: flex;
      justify-content: space-between;
      padding: ${GAP.md};

      .opt-box-btn {
        margin-right: 20px;
        overflow: hidden;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        .picker-image {
          opacity: 0;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
        }
      }
    }
  }
`;

const RotationHandle = styled.div`
  position: absolute;
  top: -17px;
  left: -17px;
  width: 15px;
  height: 15px;
  background-color: ${COLOR.green};
  border: 3px solid ${COLOR.blue};
  border-radius: 50%;
  cursor: grab;
`;

import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from 'src/common/context';
import { Layout } from 'src/components/Layout';
import { Button, GAP } from 'src/ui';
import { Embed } from 'src/components/Embed';
import { Page } from 'src/pages/Page';
import { Redirect } from 'src/App';
import { MAIN_ROUTE } from 'src/common/config';

export const Demo = () => {
  const navigate = useNavigate();
  const { views } = useAppContext();
  const { viewId } = useParams();
  const view = views.find((e) => e.id === viewId);

  const backToView = () => {
    navigate(`/view/${viewId}`);
  };

  const menu = [
    <Button
      key='backToViewButton'
      onClick={backToView}
      aria-label='Go back to the view'
    >
      Back to View
    </Button>,
  ];

  if (!view.isEnabled) {
    alert('This view is disabled and can only be deleted.');
    return <Redirect to={MAIN_ROUTE} />;
  }

  return view.isPage ? (
    <Page view={view} />
  ) : (
    <Layout page={{ title: view.title, menu }}>
      <Container className={view.type}>
        <Embed view={view} />
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: ${GAP.sm};

  &.single,
  &.slides {
    max-width: 400px;
  }

  &.tiles {
    max-width: 800px;
  }
`;

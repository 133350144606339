import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from 'src/common/context';
import {
  apiCall,
  sendEvent,
  reportError,
  truncateString,
} from 'src/common/utils';
import {
  Paragraph,
  Subheading,
  COLOR,
  TYPOGRAPHY,
  GAP,
  Loader,
  Flex,
  Button,
  MEDIA_QUERY,
} from 'src/ui';
import { Layout } from 'src/components/Layout';
import Column from 'src/components/Column';
import ViewModal from 'src/components/ViewModal';
// import { USER_PLAN } from 'src/common/config';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { views, getUserData, setModal, user, setIsLoading } = useAppContext();
  const isNewUser = !user.subscription && !user.plan;
  const isSubscribedUser = !isNewUser && (user.subscription || {}).isActive;
  const isPro = false; // for creating views vs pages
  // const isPro = user.plan.id === USER_PLAN.pro.id;
  const canCreateViews = false;
  // const canCreateViews =
  //   user.isEnabled &&
  //   user.plan &&
  //   views.filter((v) => !v.isPage).length < user.plan.embeds;
  const canCreatePages =
    user.isEnabled &&
    user.plan &&
    views.filter((v) => v.isPage).length < user.plan.pages;
  const viewTiles = [];
  const pageTiles = [];
  const tileDescLength = 120;

  if (views) {
    views.forEach((v) => {
      v.isPage ? pageTiles.push(v) : viewTiles.push(v);
    });
  }

  useEffect(() => {
    const visitorId = localStorage.getItem('slid');
    if (visitorId) {
      sendEvent({
        label: 'pageview',
        variables: { visitorId },
      }).then(() => {
        localStorage.removeItem('slid');
      });
    }
  }, []);

  // const cloumnMenu = [
  //   {
  //     id: 'menu-profile',
  //     title: 'Profile',
  //     description: 'Update your information',
  //     to: '/profile',
  //   },
  //   // ...(user.plan.id !== USER_PLAN.pro.id
  //   //   ? [
  //   //       {
  //   //         id: 'menu-plan',
  //   //         title: 'Upgrade',
  //   //         description: 'Get more out of your views',
  //   //         to: '/subscription',
  //   //       },
  //   //     ]
  //   //   : []),
  //   ...(user.plan.id === USER_PLAN.pro.id
  //     ? [
  //         {
  //           id: 'menu-howto',
  //           title: 'Guide',
  //           description: 'Learn how to embed your views',
  //           to: '/guide',
  //         },
  //       ]
  //     : []),
  // ];

  const viewItemClick = (view) => () => {
    if (isSubscribedUser && view.isEnabled) {
      navigate(`/view/${view.id}`);
    } else if (!isSubscribedUser) {
      alert(
        "You don't have an active subscription and can only delete this page.",
      );
    } else {
      alert('This page is disabled and can only be deleted.');
    }
  };

  const createView = async (formData) => {
    const {
      type: { value: type },
      isPage,
      ...variables
    } = formData;

    setIsLoading(true);

    await apiCall({
      query: 'putView',
      variables: {
        userId: user.id,
        type,
        isPage: isPage === 'true',
        ...variables,
      },
    })
      .then((success) => {
        if (success) {
          getUserData();
          setModal(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        reportError({ source: 'createView', error });
        setIsLoading(false);
      });
  };

  const deleteView = (viewId) => async (e) => {
    e.stopPropagation();

    const confirmed = confirm(
      "Delete view and its content? It can't be undone.",
    );
    if (!confirmed) return;

    setIsLoading(true);

    await apiCall({
      query: 'deleteView',
      variables: {
        viewId,
      },
    })
      .then((success) => {
        if (success) {
          getUserData();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        reportError({ source: 'deleteView', error });
        setIsLoading(false);
      });
  };

  const openViewModal = (isNewPage) => () => {
    setModal({
      content: (
        <ViewModal onSubmit={createView} user={user} isNewPage={isNewPage} />
      ),
    });
  };

  const menu = [
    // ...(user.plan.id === USER_PLAN.pro.id
    //   ? [
    //       <Button
    //         key='guideButton'
    //         onClick={() => navigate('/guide')}
    //         aria-label='Learn how to embed your views'
    //       >
    //         Guide
    //       </Button>,
    //     ]
    //   : []),
    <Button
      key='profileButton'
      onClick={() => navigate('/profile')}
      aria-label='View or change your profile and plan'
    >
      Profile
    </Button>,
    <Button
      key='logoutButton'
      onClick={() => navigate('/signout')}
      aria-label='Sign out of your account'
    >
      Signout
    </Button>,
  ];

  return (
    <Layout page={{ title: 'Dashboard', menu }}>
      <Columns>
        <Column
          isPro={isPro}
          title='Pages'
          {...(canCreatePages
            ? {
                buttons: [
                  {
                    id: 'newPageButton',
                    text: 'Create Page',
                    onClick: openViewModal(true),
                  },
                ],
              }
            : {})}
        >
          {!views && <ColumnLoader />}
          {!pageTiles.length && <ColumnNote>No pages</ColumnNote>}
          {!!views && !!pageTiles.length && (
            <Tiles>
              {pageTiles.map((v) => (
                <ColumnTile key={v.id} onClick={viewItemClick(v)}>
                  <Flex dir='column'>
                    <Subheading>{truncateString(v.title)}</Subheading>
                    {v.description && (
                      <em>
                        {truncateString(v.description, tileDescLength, true)}
                      </em>
                    )}
                  </Flex>
                  <button
                    onClick={deleteView(v.id)}
                    aria-label={`Delete page titled ${v.title}`}
                  >
                    Delete
                  </button>
                </ColumnTile>
              ))}
            </Tiles>
          )}
        </Column>

        {isPro && (
          <Column
            isPro={isPro}
            title='Views'
            {...(canCreateViews
              ? {
                  buttons: [
                    {
                      id: 'newViewButton',
                      text: 'Create View',
                      onClick: openViewModal(),
                    },
                  ],
                }
              : {})}
          >
            {!views && <ColumnLoader />}
            {!!views && !views.length && <ColumnNote>No views</ColumnNote>}
            {!!views && (
              <Tiles>
                {viewTiles.map((v) => (
                  <ColumnTile key={v.id} onClick={viewItemClick(v)}>
                    <Flex dir='column'>
                      <Subheading>{truncateString(v.title)}</Subheading>
                      {v.description && (
                        <em>{truncateString(v.description, tileDescLength)}</em>
                      )}
                    </Flex>
                    <button
                      onClick={deleteView(v.id)}
                      aria-label={`Delete view titled ${v.title}`}
                    >
                      Delete
                    </button>
                  </ColumnTile>
                ))}
              </Tiles>
            )}
          </Column>
        )}
      </Columns>
    </Layout>
  );
};

const ColumnLoader = () => (
  <Flex justifyContent='center' margin={GAP.sm}>
    <Loader small inline />
  </Flex>
);

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERY.md} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ColumnNote = styled(Paragraph)`
  margin: 0;
  padding: ${GAP.md};
  text-align: center;
  font-style: italic;
`;

// const ColumnItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin: 0;
//   padding: ${GAP.sm};
//   color: ${COLOR.black};
//   font-size: ${TYPOGRAPHY.size.sm};
//   text-decoration: none;
//   hyphens: auto;
//   -webkit-hyphens: auto;
//   cursor: pointer;

//   &:last-child {
//     margin-bottom: 0;
//   }

//   h2 {
//     margin-bottom: 0;
//   }

//   em {
//     display: block;
//     margin-top: ${GAP.xxs};
//     color: #999;
//     font-size: ${TYPOGRAPHY.size.sm};
//   }

//   &:hover {
//     background-color: ${COLOR.black} !important;

//     h2 {
//       color: ${COLOR.green};
//     }

//     button {
//       opacity: 1;
//       pointer-events: all;
//     }
//   }

//   button {
//     margin: 0;
//     padding: 0;
//     border: 0;
//     width: 20px;
//     height: 20px;
//     line-height: 18px;
//     border-radius: 3px;
//     background-color: ${COLOR.lightGray};
//     color: ${COLOR.black};
//     font-size: 18px;
//     cursor: pointer;
//     opacity: 0;
//     pointer-events: none;
//   }
// `;

const Tiles = styled.div`
  padding: ${GAP.sm} 0;

  ${MEDIA_QUERY.md} {
    padding: ${GAP.sm};
  }
`;

const ColumnTile = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 ${GAP.sm} 0;
  padding: ${GAP.sm};
  width: 100%;
  height: 100px;
  border: 1px dashed ${COLOR.darkGray};
  border-radius: 4px;
  background-color: ${COLOR.white};
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};
  text-decoration: none;
  hyphens: auto;
  -webkit-hyphens: auto;
  transition: 0.3s box-shadow;
  cursor: pointer;

  ${MEDIA_QUERY.md} {
    margin: 0 ${GAP.md} ${GAP.md} 0;
    width: 200px;
    height: 200px;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  em {
    display: block;
    margin-top: ${GAP.sm};
    color: #999;
    font-size: ${TYPOGRAPHY.size.sm};
  }

  &:hover {
    border: 1px solid ${COLOR.black} !important;
    box-shadow: 0px 1px 3px ${COLOR.darkGray};

    button {
      opacity: 1;
      pointer-events: all;
    }
  }

  button {
    position: absolute;
    top: -1px;
    right: -1px;
    margin: 0;
    padding: 3px 6px;
    height: 20px;
    background-color: ${COLOR.white};
    color: ${COLOR.black};
    border: 1px solid ${COLOR.black};
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    font-family: system-ui;
    border-top-right-radius: 4px;
    cursor: pointer;

    ${MEDIA_QUERY.md} {
      background-color: ${COLOR.black};
      color: ${COLOR.white};
      border: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
`;

import styled from 'styled-components';
import { Layout } from 'src/components/Layout';
import { COLOR, TYPOGRAPHY } from 'src/ui';

export const Error404 = () => {
  return (
    <Layout>
      <Content>Page not found</Content>
    </Layout>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 36px);
  background-color: ${COLOR.blue};
  color: ${COLOR.white};
  font-size: ${TYPOGRAPHY.size.xxl};
`;

import { useEffect, useState } from 'react';
import axios from 'axios';
import { throttle } from 'lodash';
// import { del as idbDel } from 'idb-keyval';
import {
  TOKEN_STORAGE,
  BANNER_STORAGE,
  API_URL,
  IS_PROD,
  ALLOWED_IMAGES,
  ALLOWED_IMAGE_CONTENT_TYPES,
  SIGNIN_URL,
  SIGNIN_REDIRECT_STORAGE,
} from './config';

export const hasToken = () => localStorage.getItem(TOKEN_STORAGE) !== null;

export const hasBannerOk = () => localStorage.getItem(BANNER_STORAGE) !== null;

export const reportError = ({ source = 'app', error }) => {
  if (!IS_PROD) console.error(`${source}:`, error);
};

// export const clearDataCache = () => idbDel('getUserData');

export const apiCall = async ({ query, variables, authRequired = true }) => {
  let token = null;
  if (authRequired) {
    token = localStorage.getItem(TOKEN_STORAGE);
    if (!token) throw new Error('Unauthorized.');
  }
  return axios
    .post(
      API_URL,
      { query, variables },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    .then((result) => result?.data?.data || null)
    .catch((error) => {
      const isUnAuthError = error.message.includes('401');
      if (isUnAuthError && authRequired) {
        localStorage.setItem(SIGNIN_REDIRECT_STORAGE, window.location.pathname);
        setTimeout(() => {
          window.location = SIGNIN_URL;
        }, 200);
      } else {
        throw error;
      }
    });
};

export const sendEvent = async (data) => {
  if (!IS_PROD) return;
  if (!data) throw new Error('No event data was provided.');
  if (!data.label) throw new Error('Malformed data was provided.');
  if (data.payload) data.payload = JSON.stringify(data.payload);
  if (document.referrer) data.referrer = document.referrer;
  data.path = window.location.pathname;
  const visitorId = localStorage.getItem('slid');
  if (visitorId) data.visitorId = visitorId;
  return apiCall({ query: 'putEvent', variables: data, authRequired: false });
};

export const isMediaFileUrl = (string) => {
  return new RegExp(`^https://(?:[a-z0-9-./]*).(${ALLOWED_IMAGES})$`, 'i').test(
    string,
  );
};

export const getFileExtFromUrl = (string) => {
  return string
    .toLowerCase()
    .match(new RegExp(`.(${ALLOWED_IMAGES})$`, 'i'))[1];
};

export const getDataStringAndContentTypeFromDataUrl = (string) => {
  const contentType = string
    .toLowerCase()
    .match(
      new RegExp(`^data:(image/(?:${ALLOWED_IMAGE_CONTENT_TYPES}));`, 'i'),
    )[1];

  const ext = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
  }[contentType];

  return {
    dataString: string.replace(`data:${contentType};base64,`, ''),
    contentType,
    ext,
  };
};

export const createIdfromUrl = (url) => {
  return url.replace(/https?:\/\/|www|\./gi, '');
};

export const truncateString = (input, limit = 30, isHTML = false) => {
  let string = input;
  if (isHTML) {
    const span = document.createElement('div');
    span.innerHTML = input;
    string = span.innerText;
  }
  return `${string.substring(0, limit)}${string.length > limit ? '...' : ''}`;
};

export const snakeToCamelCase = (string) => {
  return string
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );
};

export const parseParamString = (paramString) => {
  return paramString
    .replace('?', '')
    .replace('#', '')
    .split('&')
    .map((v) => v.split('='))
    .reduce(
      (pre, [key, value]) => ({
        ...pre,
        [snakeToCamelCase(key)]: value,
      }),
      {},
    );
};

export class ScrollDepth {
  constructor(settings) {
    this.settings = {
      throttle: 500,
      scrollElement: document.documentElement,
      percentages: [25, 50, 75, 99],
      pixelDepthInterval: 0,
      elements: [],
      percentageDepthLabel: 'scroll',
      pixelDepthLabel: 'pixelDepth',
      elementLabel: 'elementInView',
      onEvent: () => {},
      onScroll: () => {},
      ...settings,
    };
    this.greatestScrollTop = 0;
  }

  percentageDepth() {
    const scrollRatio =
      this.settings.scrollElement.scrollTop /
      (this.settings.scrollElement.scrollHeight -
        this.settings.scrollElement.clientHeight);
    this.settings.percentages.forEach((point, index) => {
      if (scrollRatio * 100 >= point) {
        this.settings.percentages.splice(index, 1);
        this.settings.onEvent({
          label: this.settings.percentageDepthLabel,
          payload: point,
        });
      }
    });
    if (this.settings.onScroll) {
      this.settings.onScroll(scrollRatio);
    }
  }

  pixelDepth() {
    const scrollTop = this.settings.scrollElement.scrollTop;
    while (
      scrollTop >=
      this.greatestScrollTop + this.settings.pixelDepthInterval
    ) {
      this.greatestScrollTop += this.settings.pixelDepthInterval;
      this.settings.onEvent({
        label: this.settings.pixelDepthLabel,
        payload: this.greatestScrollTop + this.settings.pixelDepthInterval,
      });
    }
  }

  elements() {
    this.settings.elements.forEach((elementId, index) => {
      const element = document.getElementById(elementId);
      if (
        element &&
        element.offsetTop &&
        element.clientHeight &&
        element.offsetTop + element.clientHeight <
          this.settings.scrollElement.clientHeight +
            this.settings.scrollElement.scrollTop
      ) {
        this.settings.elements.splice(index, 1);
        this.settings.onEvent({
          label: this.settings.elementLabel,
          payload: elementId,
        });
      }
    });
  }

  onScroll() {
    if (this.settings.percentages) {
      this.percentageDepth();
    }
    if (this.settings.pixelDepthInterval) {
      this.pixelDepth();
    }
    if (this.settings.elements.length) {
      this.elements();
    }
  }

  stop() {
    window.removeEventListener('scroll', this.watch, true);
  }

  start() {
    this.watch = this.settings.throttle
      ? throttle(this.onScroll.bind(this), this.settings.throttle)
      : this.onScroll.bind(this);
    window.addEventListener('scroll', this.watch, true);
  }
}

export const useElementOnScreen = ({
  ref,
  topMargin = '0px',
  threshold = 1,
  watch,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(true);

  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (!watch && isIntersecting) {
            setIsIntersecting(true);
            observer.unobserve(root);
          } else {
            setIsIntersecting(isIntersecting);
          }
        },
        {
          rootMargin: topMargin,
          threshold,
        },
      );

      const root = ref.current;

      if (root) {
        observer.observe(root);
      }

      return () => {
        if (root) {
          observer.unobserve(root);
        }
      };
    }
  }, [ref, topMargin, threshold, watch]);

  return isIntersecting;
};

import { useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'src/components/Layout';
import { Embed } from 'src/components/Embed';
import {
  COLOR,
  Flex,
  GAP,
  MEDIA_QUERY,
  Paragraph,
  Subheading,
  Button,
} from 'src/ui';
import { SITE_TITLE } from 'src/common/config';

export const Page = ({ view, isSSR }) => {
  const {
    avatar,
    title,
    description,
    websiteUrl,
    websiteText,
    websiteIcon,
    youtube,
    facebook,
    instagram,
    twitter,
    tiktok,
    discord,
    twitch,
    amazon,
    etsy,
    ltk,
    poshmark,
    pinterest,
    defaultIcons,
    ctas,
  } = view;

  const hasSocial =
    youtube ||
    facebook ||
    instagram ||
    twitter ||
    tiktok ||
    discord ||
    twitch ||
    amazon ||
    etsy ||
    ltk ||
    poshmark ||
    pinterest;

  const iconSuffix = [true, undefined, null].includes(defaultIcons)
    ? ''
    : '-minimal';

  useEffect(() => {
    if (view) {
      document.title = `${view.title} | ${SITE_TITLE}`;
    }
  }, [view]);

  return (
    <Layout
      page={{
        logo: false,
        ...(!!view && !!view.bgColor ? { bgColor: view.bgColor } : {}),
      }}
      isSSR={isSSR}
    >
      {!!view && (
        <Container>
          <Content>
            <Title>
              {avatar && <Avatar src={avatar} alt='Page avatar' />}
              <Subheading
                style={{
                  ...(!!view.bgColor && !!view.txtColor
                    ? { color: view.txtColor }
                    : {}),
                }}
              >
                {title}
              </Subheading>
            </Title>
            {(hasSocial || description || websiteUrl) && (
              <DescContainer>
                {description && (
                  <Desc dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {websiteUrl && (
                  <Paragraph
                    margin={`${description ? GAP.md : 0} 0 ${GAP.md} 0`}
                  >
                    <Link href={websiteUrl} target='_blank' rel='noreferrer'>
                      {websiteIcon && (
                        <WebsiteIcon
                          src={websiteIcon}
                          alt='Website icon'
                          aria-hidden='true'
                        />
                      )}
                      <span>{websiteText || websiteUrl}</span>
                    </Link>
                  </Paragraph>
                )}
                {hasSocial && (
                  <SocialLinks
                    style={{
                      margin: `${description || websiteUrl ? GAP.md : 0} 0 0 0`,
                    }}
                  >
                    {youtube && (
                      <SocialLink
                        title='YouTube'
                        href={youtube}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/youtube${iconSuffix}.png`}
                          alt='YouTube'
                          className={defaultIcons ? '' : 'big'}
                        />
                      </SocialLink>
                    )}
                    {facebook && (
                      <SocialLink
                        title='Facebook'
                        href={facebook}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/facebook${iconSuffix}.png`}
                          alt='Facebook'
                        />
                      </SocialLink>
                    )}
                    {instagram && (
                      <SocialLink
                        title='Instagram'
                        href={instagram}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/instagram${iconSuffix}.png`}
                          alt='Instagram'
                          className={defaultIcons ? 'smaller' : 'small'}
                        />
                      </SocialLink>
                    )}
                    {twitter && (
                      <SocialLink
                        title='Twitter'
                        href={twitter}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/twitter${iconSuffix}.png`}
                          alt='Twitter'
                          className={defaultIcons ? 'big' : 'small'}
                        />
                      </SocialLink>
                    )}
                    {tiktok && (
                      <SocialLink
                        title='TikTok'
                        href={tiktok}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/tiktok${iconSuffix}.png`}
                          alt='TikTok'
                          className={defaultIcons ? 'smaller' : 'small'}
                        />
                      </SocialLink>
                    )}
                    {discord && (
                      <SocialLink
                        title='Discord'
                        href={discord}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/discord${iconSuffix}.png`}
                          alt='Discord'
                          className={defaultIcons ? 'small' : ''}
                        />
                      </SocialLink>
                    )}
                    {twitch && (
                      <SocialLink
                        title='Twitch'
                        href={twitch}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/twitch${iconSuffix}.png`}
                          alt='Twitch'
                          className={defaultIcons ? '' : 'smaller'}
                        />
                      </SocialLink>
                    )}
                    {amazon && (
                      <SocialLink
                        title='Amazon'
                        href={amazon}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/amazon${iconSuffix}.png`}
                          alt='Amazon'
                          className={defaultIcons ? '' : 'small'}
                        />
                      </SocialLink>
                    )}
                    {etsy && (
                      <SocialLink
                        title='Etsy'
                        href={etsy}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/etsy${iconSuffix}.png`}
                          alt='Etsy'
                          className='smaller'
                        />
                      </SocialLink>
                    )}
                    {ltk && (
                      <SocialLink
                        title='LTK'
                        href={ltk}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/ltk${iconSuffix}.png`}
                          alt='LTK'
                          className='smaller'
                        />
                      </SocialLink>
                    )}
                    {poshmark && (
                      <SocialLink
                        title='Poshmark'
                        href={poshmark}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/poshmark${iconSuffix}.png`}
                          alt='Poshmark'
                          className='smaller'
                        />
                      </SocialLink>
                    )}
                    {pinterest && (
                      <SocialLink
                        title='Pinterest'
                        href={pinterest}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={`/assets/image/pinterest${iconSuffix}.png`}
                          alt='Pinterest'
                          className='smaller'
                        />
                      </SocialLink>
                    )}
                  </SocialLinks>
                )}
              </DescContainer>
            )}
            {!!ctas?.length && (
              <CTA isFourCols={ctas.length >= 4 && ctas.length % 2 === 0}>
                {ctas.map((cta) => (
                  <Button
                    key={cta.id}
                    id={cta.id}
                    as='a'
                    href={cta.url}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {cta.label}
                  </Button>
                ))}
              </CTA>
            )}
          </Content>
          <Embed view={view} />
        </Container>
      )}
    </Layout>
  );
};

const Container = styled.div`
  margin: 0 auto;
  padding: ${GAP.md} 3px;
  max-width: 800px;
`;

const Content = styled.div`
  margin: 0;
  padding: 0 ${GAP.xs} ${GAP.md} ${GAP.xs};

  ${MEDIA_QUERY.sm} {
    padding: 0 0 ${GAP.md} 0;
  }
`;

const Title = styled(Flex)`
  flex-direction: column;
  margin: 0 0 ${GAP.sm} 0;
  padding: 0;
  justify-content: center;
  align-items: center;

  h2 {
    display: inline-block;
    margin: 0;
    color: ${COLOR.black};
    width: auto;
  }
`;

const Avatar = styled.img`
  display: inline-block;
  margin: 0 0 ${GAP.sm} 0;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  /* box-shadow: 0px 1px 3px ${COLOR.darkGray}; */
`;

const DescContainer = styled.div`
  margin: 0;
  padding: ${GAP.sm};
  background-color: ${COLOR.white};
  box-shadow: 0px 1px 3px ${COLOR.darkGray};
  border-radius: 4px;
  text-align: center;
`;

const Desc = styled.div`
  p {
    margin-top: 0;
    margin-bottom: ${GAP.xxs};
  }

  ul,
  ol {
    margin-top: 0;
    padding-left: ${GAP.sm};
    text-align: left;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${COLOR.black};
  text-decoration: none;

  span {
    text-decoration: underline;
  }
`;

const WebsiteIcon = styled.img`
  margin: 0 ${GAP.xxs} 0 0;
  width: 22px;
  height: 22px;
`;

const SocialLink = styled.a`
  display: block;
  width: 30px;
  height: 30px;
  transform-origin: center;
  margin-right: ${GAP.xs};
  margin-bottom: ${GAP.xs};

  ${MEDIA_QUERY.sm} {
    margin-right: ${GAP.md};
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;

    &:not([src$='-minimal.png']) {
      &.smaller {
        transform: scale(0.8);
      }

      &.small {
        transform: scale(0.9);
      }

      &.big {
        transform: scale(1.1);
      }
    }
  }
`;

const CTA = styled.div`
  margin: ${GAP.md} 0 0 0;
  text-align: center;

  ${MEDIA_QUERY.sm} {
    grid-template-columns: ${({ isFourCols }) =>
      isFourCols ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  }

  a {
    display: block;
    text-decoration: none;
    border: 1px solid ${COLOR.black};
    margin: 0 0 ${GAP.xs} 0;
    /* box-shadow: 0px 1px 3px ${COLOR.darkGray}; */

    &:hover {
      color: ${COLOR.black};
      background-color: ${COLOR.white};
      border-color: ${COLOR.black};
    }

    ${MEDIA_QUERY.sm} {
      display: inline-block;
      width: 30%;
      margin: 0 ${GAP.xs} ${GAP.xs} ${GAP.xs};

      &:hover {
        color: ${COLOR.white};
        background-color: ${COLOR.black};
        border-color: ${COLOR.black};
      }
    }
  }
`;

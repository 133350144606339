import { useCallback, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import axios from 'axios';
import { AppContext } from 'src/common/context';
import {
  SIGNUP_URL,
  SIGNIN_URL,
  TOKEN_STORAGE,
  SIGNIN_REDIRECT_STORAGE,
  MAIN_ROUTE,
  IS_PROD,
} from 'src/common/config';
import {
  hasToken,
  apiCall,
  parseParamString,
  reportError,
} from 'src/common/utils';
import { Layout } from 'src/components/Layout';
import { GlobalStyles } from 'src/components/GlobalStyles';
import { Error404 } from 'src/pages/Error/404';
// import { Home } from 'src/pages/Home';
import { Landing } from 'src/pages/Landing';
import { Blog } from './pages/Blog';
import { Dashboard } from 'src/pages/Dashboard';
import { View } from 'src/pages/View';
import { Image } from 'src/pages/Image';
import { Demo } from 'src/pages/Demo';
import { Subscription } from 'src/pages/Subscription';
// import { Guide } from 'src/pages/Guide';
import { Profile } from 'src/pages/Profile';
import { UserDisabled } from 'src/pages/UserDisabled';
import { Start } from 'src/pages/Start';
import { Privacy } from 'src/pages/Privacy';
import { Signout } from 'src/pages/Signout';
import { Loader } from './ui';

export const Redirect = ({ to }) => {
  if (to.startsWith('http')) {
    window.location = to;
    return null;
  }
  return <Navigate to={to} />;
};

const ForwardToIndex = () => {
  const [state, setState] = useState(<Loader />);
  useEffect(() => {
    const url = window.location.href;
    const indexHtml = `${url}${url.endsWith('/') ? '' : '/'}index.html`;
    axios
      .get(indexHtml)
      .then((resp) => {
        const foundPageIndex = !resp?.data?.includes('<div id="app"></div>');
        if (foundPageIndex) {
          document.open();
          document.write(resp.data);
          document.close();
        } else {
          setState(<Error404 />);
        }
      })
      .catch(() => {
        setState(<Error404 />);
      });
  }, []);
  return state;
};

const SigninHandler = () => {
  const { hash } = useLocation();
  const { idToken } = parseParamString(hash);
  localStorage.setItem(TOKEN_STORAGE, idToken);
  const redirectTo = localStorage.getItem(SIGNIN_REDIRECT_STORAGE);
  if (redirectTo) localStorage.removeItem(SIGNIN_REDIRECT_STORAGE);
  return <Redirect to={redirectTo || MAIN_ROUTE} />;
};

const SignoutHandler = () => {
  if (hasToken()) {
    localStorage.removeItem(TOKEN_STORAGE);
  }
  // clearDataCache().then(() => {
  window.location = '/signedout';
  // });
};

const renderRouteElement = ({
  authRequired,
  path,
  element,
  user,
  isLoadingData,
}) => {
  if (authRequired && !hasToken()) {
    return <Redirect to={SIGNIN_URL} />;
  }
  if (authRequired && isLoadingData) {
    return <Layout page={{ isLoading: true }} />;
  }
  if (authRequired && !user.isEnabled && path !== '/user') {
    return <Redirect to='/user' />;
  }
  if (
    authRequired &&
    user.isEnabled &&
    !user.subscription &&
    path !== '/subscription'
  ) {
    return <Redirect to='/subscription' />;
  }
  if (
    authRequired &&
    user.isEnabled &&
    !user.subscription?.isActive &&
    !['/subscription', '/dashboard', '/profile'].includes(path)
  ) {
    return <Redirect to='/subscription' />;
  }
  return element;
};

const routes = [
  // {
  //   path: '/',
  //   exact: true,
  //   element: IS_PROD && hasToken() ? <Redirect to={MAIN_ROUTE} /> : <Home />,
  // },
  {
    path: '/',
    exact: true,
    element:
      IS_PROD && hasToken() ? (
        <Redirect to={MAIN_ROUTE} />
      ) : (
        <Redirect to='/social' />
      ),
  },
  { path: '/social', element: <Landing /> },
  { path: '/landing', element: <Redirect to='/social' /> },
  { path: '/signup', element: <Redirect to={SIGNUP_URL} /> },
  { path: '/signin', exact: true, element: <Redirect to={SIGNIN_URL} /> },
  { path: '/signin/redirect', element: <SigninHandler /> },
  { path: '/signout', exact: true, element: <SignoutHandler /> },
  { path: '/signout/redirect', element: <Signout /> },
  { path: '/signedout', element: <Signout /> },
  { path: MAIN_ROUTE, element: <Dashboard />, authRequired: true },
  { path: '/view/:viewId/:imageId', element: <Image />, authRequired: true },
  { path: '/view/:viewId', element: <View />, authRequired: true },
  { path: '/demo/:viewId', element: <Demo />, authRequired: true },
  { path: '/subscription', element: <Subscription />, authRequired: true },
  // { path: '/guide', element: <Guide />, authRequired: true },
  { path: '/profile', element: <Profile />, authRequired: true },
  { path: '/user', element: <UserDisabled />, authRequired: true },
  { path: '/start', element: <Start /> },
  { path: '/privacy', element: <Privacy /> },
  { path: '/terms', element: <Redirect to='/privacy#terms' /> },
  { path: '/blog', exact: true, element: <Blog /> },
  { path: '/blog/:postId', element: <ForwardToIndex /> },
  { path: '/p/:pageId', element: <ForwardToIndex /> },
];

const publicRoutes = routes.reduce(
  (r, e) => (!e.authRequired ? [...r, e.path] : r),
  [],
);

const App = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [modal, setModal] = useState(null);
  const [views, setViews] = useState();
  const isLoadingData = !user || !views;

  const getUserData = useCallback(async () => {
    setIsLoading(true);
    await apiCall({ query: 'getUserData' })
      .then((data) => {
        if (data) {
          setUser(data.user);
          setViews(data.views);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        reportError({ source: 'getUserData', error });
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      !publicRoutes.includes(window.location.pathname) &&
      !window.location.pathname.startsWith('/p/') &&
      hasToken()
    ) {
      getUserData();
    }
  }, [getUserData]);

  const appContext = {
    error,
    setError,
    isLoading,
    setIsLoading,
    modal,
    setModal,
    user,
    setUser,
    views,
    setViews,
    getUserData,
  };

  return (
    <AppContext.Provider value={appContext}>
      <GlobalStyles />
      <Router>
        <Routes>
          {routes.map(
            ({ path, exact = false, element, authRequired = false }) => (
              <Route
                key={path}
                path={path}
                {...(exact ? { exact } : {})}
                element={renderRouteElement({
                  authRequired,
                  path,
                  element,
                  user,
                  isLoadingData,
                })}
              />
            ),
          )}
          <Route path='*' element={<Error404 />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
};

export default App;

import { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Button, COLOR, GAP, Paragraph } from 'src/ui';
import { BANNER_STORAGE, MAX_WIDTH } from 'src/common/config';

export const DataBanner = () => {
  const [hide, setHide] = useState(false);

  const accept = () => {
    localStorage.setItem(BANNER_STORAGE, 'true');
    setHide(true);
  };

  return (
    <Wrapper className={cn({ hide })}>
      <Inner>
        <Paragraph>
          Thanks for being here! We collect page views and clicks to measure
          marketing efforts.
        </Paragraph>
        <Button onClick={accept}>OK</Button>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: ${COLOR.black};
  transition: 0.3s all;
  transform: translate3d(0, 1px, 0);
  z-index: 1;

  &.hide {
    transform: translate3d(0, 110%, 0);
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${GAP.sm};
  padding: ${GAP.sm} 0;
  width: 100%;
  max-width: ${MAX_WIDTH};

  p {
    margin: 0 ${GAP.sm} 0 0;
    color: ${COLOR.white};
  }
`;

import { useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  COLOR,
  GAP,
  Heading,
  MEDIA_QUERY,
  Paragraph,
  Subheading,
  TYPOGRAPHY,
} from 'src/ui';
import { hasBannerOk, sendEvent } from 'src/common/utils';
import { DataBanner } from 'src/components/DataBanner';
import {
  SITE_TITLE,
  MAX_WIDTH,
  IS_MAINTENANCE_MODE,
  USER_PLAN,
} from 'src/common/config';
import { BounceIn } from 'src/ui/animation/BounceIn';
import { v4 as uuidv4 } from 'uuid';

export const Landing = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaign = params.get('c') || 'direct';

  useEffect(() => {
    const visitorId = localStorage.getItem('slid');
    if (!visitorId) {
      localStorage.setItem('slid', uuidv4());
    }
    sendEvent({
      label: 'pageview',
      campaign,
    });
  }, []); // eslint-disable-line

  const ctaClick = (cta) => () => {
    sendEvent({
      label: 'click',
      campaign,
      cta,
    });
    navigate('/signup');
  };

  if (IS_MAINTENANCE_MODE) {
    return (
      <Heading>
        {SITE_TITLE} is currently down for scheduled maintenance.
      </Heading>
    );
  }

  return (
    <>
      <BlogButton
        small
        transparent
        aria-label='Read the blog'
        onClick={() => {
          navigate('/blog');
        }}
      >
        Blog
      </BlogButton>
      <SigninButton
        small
        transparent
        aria-label='Sign in'
        onClick={() => {
          navigate('/signin');
        }}
      >
        Sign In
      </SigninButton>
      <Wrapper>
        <Inner>
          <Hero>
            <img src='/favicon-128x128.png' alt={`${SITE_TITLE} Logo`} />
            <Heading>
              <span>Transform Your Influence and Increase Conversions</span>
            </Heading>
            <Button big shadow onClick={ctaClick('start')}>
              Get Started
            </Button>
          </Hero>
          <Content>
            <Section>
              <div>
                <Subheading>
                  <span>Revolutionize your social media game</span>
                </Subheading>
                <Paragraph>
                  Whether you're an Amazon aficionado, a fashion guru, or a
                  brand powerhouse, imagine the products you recommend selling
                  out at lightning speed while building a fiercely loyal
                  community.
                </Paragraph>
              </div>
            </Section>
            <Section>
              <div>
                <Paragraph>
                  <ul>
                    <li>
                      Social media platforms are designed to keep users on-site,
                      making it hard for your followers to access your links.
                    </li>
                    <li>
                      Traditional link in bio sites offer a series of plain link
                      buttons that look the same.
                    </li>
                    <li>
                      Others force your followers to comment asking for links
                      and cluttering the comments section.
                    </li>
                    <li>
                      <b>This friction costs you conversions and money.</b>
                    </li>
                  </ul>
                </Paragraph>
              </div>
              <div className='img'>
                <img
                  src='/assets/image/landing1.jpg'
                  alt='Social bio link example'
                />
                <img
                  src='/assets/image/landing2.jpg'
                  alt='Social bio site example'
                />
              </div>
            </Section>
            <Section className='gap'>
              <div>
                <Subheading>
                  <span>
                    Spott.Link enables your followers to shop directly from
                    images they love
                  </span>
                </Subheading>
                <Paragraph>
                  Link to multiple products from a single image using{' '}
                  <b>hotspots</b>.
                </Paragraph>
                <Paragraph>
                  No more jumping through hoops or cluttered comments.
                </Paragraph>
                <Paragraph>
                  Your followers will appreciate the streamlined experience.
                </Paragraph>
                <Paragraph>
                  See{' '}
                  <a
                    href='https://spott.link/p/example'
                    target='_blank'
                    rel='noreferrer'
                  >
                    example page
                  </a>
                  .
                </Paragraph>
              </div>
              <div className='img'>
                <img
                  src='/assets/image/sl-page-screenshot.png'
                  alt='Social bio Spot Link example'
                />
              </div>
            </Section>
            <Cta>
              <Subheading>Create Your Webpage</Subheading>
              <Paragraph>
                Starting at <s>$20</s> <span>$10/month</span>
              </Paragraph>
              <ul>
                <li>{USER_PLAN.starter.pages} customizable public page</li>
                <li>{USER_PLAN.starter.imagesPerPage} images per page</li>
                <li>
                  {USER_PLAN.starter.hotspotsPerImage} hotspot links per image
                </li>
                <li>3 view types (tiles, slides, single)</li>
                <li>{USER_PLAN.starter.customLinks} link buttons per page</li>
                <li>No branding or watermarks</li>
              </ul>
              <BounceIn topMargin='-100px'>
                <Button big primary shadow onClick={ctaClick('signup')}>
                  Sign Up Now
                </Button>
              </BounceIn>
              <Em>
                Choose plan and setup billing after signup.
                <br />
                Cancel anytime.
              </Em>
              <PayPalImg
                src='https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/Full_Online_Tray_RGB.png'
                alt='PayPal Acceptance Mark'
              />
            </Cta>
          </Content>
          <Footer>
            <div className='social'>
              <a
                href='https://facebook.com/spott.link'
                target='_blank'
                rel='noreferrer'
              >
                Facebook
              </a>{' '}
              &#9679;{' '}
              <a
                href='https://instagram.com/spott.link'
                target='_blank'
                rel='noreferrer'
              >
                Instagram
              </a>{' '}
              &#9679;{' '}
              <a
                href='https://tiktok.com/@spott.link'
                target='_blank'
                rel='noreferrer'
              >
                TikTok
              </a>{' '}
              &#9679;{' '}
              <a
                href='https://pinterest.ca/spottlink'
                target='_blank'
                rel='noreferrer'
              >
                Pinterest
              </a>
            </div>
            <div>
              &copy; {new Date().getFullYear()} {SITE_TITLE}
              <img
                src='/assets/icon/canada-leaf.ico'
                alt='The Red Maple Leaf of Canada'
              />
              <Link to='/privacy'>Privacy and Terms</Link>
            </div>
          </Footer>
        </Inner>
      </Wrapper>
      {!hasBannerOk() && <DataBanner />}
    </>
  );
};

const SigninButton = styled(Button)`
  position: absolute;
  top: ${GAP.sm};
  right: ${GAP.sm};
  color: ${COLOR.white}90;
  background-color: ${COLOR.black}80;
  cursor: pointer;
  z-index: 99;

  &:hover,
  &:focus {
    background-color: ${COLOR.black};
  }
`;

const BlogButton = styled(SigninButton)`
  right: 120px;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  background-color: ${COLOR.blue};
`;

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0;
  max-width: ${MAX_WIDTH};
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${GAP.md};
  margin: 0 auto;
  padding: ${GAP.xxl} ${GAP.md} ${GAP.md} ${GAP.md};
  min-height: 90vh;

  ${MEDIA_QUERY.md} {
    align-items: flex-start;
    text-align: left;
    padding: ${GAP.md};
  }

  img {
    margin: 0;
    padding: 15px;
    background-color: ${COLOR.white};
    border-radius: 50%;
    border-bottom-right-radius: 30px;
    width: 94px;
    height: 94px;
    box-shadow: 2px 2px 0px ${COLOR.black}, 4px 4px 0px ${COLOR.black},
      6px 6px 0px ${COLOR.black}, 8px 8px 0px ${COLOR.black},
      10px 10px 0px ${COLOR.black}, 12px 12px 0px ${COLOR.black};
  }

  h1 {
    margin: 0;
    font-size: 30px;

    ${MEDIA_QUERY.md} {
      font-size: 5vw;
      letter-spacing: 2px;
    }

    span {
      padding: 5px 0;
      background-color: ${COLOR.black};
      box-shadow: 15px 0 0 ${COLOR.black}, -15px 0 0 ${COLOR.black};
      box-decoration-break: clone;
      color: ${COLOR.green};
      border-radius: 4px;
      line-height: 1.7;

      ${MEDIA_QUERY.md} {
        line-height: 1.5;
      }
    }
  }

  button {
    margin: 0 0 0 -10px;
    background-color: ${COLOR.white};
    color: ${COLOR.black};
    font-weight: bold;

    &:hover,
    &:focus {
      background-color: ${COLOR.black};
      color: ${COLOR.white};
    }
  }
`;

const Content = styled.div`
  margin: ${GAP.md} auto 0 auto;
  padding: 0;
  min-height: 400px;
  border-radius: 8px;
  overflow: hidden;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP.md};
  margin: 0 0 ${GAP.sm} 0;
  padding: ${GAP.xl} ${GAP.lg};
  background-color: ${COLOR.white};
  border-radius: 8px;

  > div.img {
    display: flex;
    flex-direction: column;
    gap: ${GAP.sm};
    margin: 0 auto;

    img {
      min-width: 200px;
      max-width: 200px;
      border-radius: 8px;
    }
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: row;
    justify-content: space-between;
    gap: ${GAP.xl};

    &.gap {
      gap: 200px;
    }

    &.alt {
      flex-direction: row-reverse;
    }

    > div.img {
      flex-direction: row;
    }
  }

  /* h2 {
    margin: 0 auto ${GAP.sm} 0;
    display: inline-block;
    width: auto;
    padding: 3px 0;
    color: ${COLOR.white};
    background-color: ${COLOR.blue};
    box-decoration-break: clone;
    box-shadow: 5px 0 0 ${COLOR.blue}, -5px 0 0 ${COLOR.blue};
    border-radius: 4px;
  } */

  h2 {
    margin: 0 auto ${GAP.sm} 0;

    span {
      padding: 5px 0;
      background-color: ${COLOR.blue};
      box-shadow: 15px 0 0 ${COLOR.blue}, -15px 0 0 ${COLOR.blue};
      box-decoration-break: clone;
      color: ${COLOR.white};
      border-radius: 4px;
      line-height: 1.7;

      ${MEDIA_QUERY.md} {
        line-height: 1.5;
      }
    }
  }

  p {
    font-size: 20px;
    line-height: 2;

    > span {
      padding: 3px 0;
      background-color: ${COLOR.white};
      box-decoration-break: clone;
      box-shadow: 5px 0 0 ${COLOR.white}, -5px 0 0 ${COLOR.white};
      border-radius: 4px;
    }
  }

  a {
    color: ${COLOR.black};
    text-decoration: underline;
  }

  ul {
    margin: 0;
    padding-left: 20px;

    li {
      line-height: 1.5;
      margin-bottom: ${GAP.sm};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Cta = styled.div`
  border-radius: 8px;
  margin: 0;
  padding: ${GAP.xl} ${GAP.lg};
  background-color: ${COLOR.white};
  text-align: center;

  h2 {
    color: ${COLOR.blue};
    font-size: 30px;

    ${MEDIA_QUERY.sm} {
      font-size: ${TYPOGRAPHY.size.xl};
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 2;
  }

  button {
    font-size: ${TYPOGRAPHY.size.lg};
    margin: ${GAP.md} auto;
  }

  s {
    color: red;
  }

  span {
    color: green;
  }

  p {
    font-size: 20px;
  }
`;

const Em = styled.em`
  display: block;
  margin: ${GAP.sm} auto 0 auto;
  color: ${COLOR.darkGray};
  font-size: 12px;
  text-align: center;
`;

const PayPalImg = styled.img`
  display: block;
  margin: ${GAP.sm} auto 0 auto;
  width: 150px;
  border: 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: ${GAP.sm};
  margin: 0 auto;
  padding: ${GAP.sm};

  ${MEDIA_QUERY.sm} {
    flex-direction: row;
    justify-content: space-between;
  }

  div {
    margin: 0;
    padding: 3px 5px;
    border-radius: 4px;
    background-color: ${COLOR.black};
    color: ${COLOR.white};
    font-size: 11px;

    &.social {
      color: ${COLOR.darkGray};

      a {
        color: ${COLOR.white};
      }
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 5px;
    height: 12px;
  }

  a {
    color: ${COLOR.gray};
    text-decoration: none;
  }
`;

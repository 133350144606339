import styled from 'styled-components';
import { Subheading, GAP, COLOR, Flex, MEDIA_QUERY } from 'src/ui';

const Column = ({ isPro, title, buttons, children }) => {
  return (
    <Wrapper isPro={isPro}>
      <Header>
        <Flex alignItems='center'>
          <Title>{title}</Title>
          {buttons &&
            buttons.map((button) => (
              <Button key={button.id} onClick={button.onClick}>
                {button.text}
              </Button>
            ))}
        </Flex>
      </Header>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  padding: ${GAP.sm};
  width: 100%;
  border-radius: 4px;
  transition: 0.3s all;

  ${MEDIA_QUERY.md} {
    margin: ${GAP.md};
    padding: 0;

    &:hover {
      background-color: ${({ isPro }) =>
        isPro ? COLOR.lightGray : 'transparent'};
    }
  }
`;

const Header = styled.div`
  margin: 0;
  padding: ${GAP.sm};
  background-color: ${COLOR.lightGray};
`;

const Title = styled(Subheading)`
  margin: 0 ${GAP.sm} 0 0;
  padding: 0;
  color: ${COLOR.black};
  width: auto;
`;

const Button = styled.button`
  margin: 0 ${GAP.xs} 0 0;
  padding: 3px 5px;
  border: 0;
  border-radius: 4px;
  background-color: ${COLOR.black};
  color: ${COLOR.white};
  font-size: 12px;
  cursor: pointer;
`;

export default Column;

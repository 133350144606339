import styled from 'styled-components';
import { COLOR, MEDIA_QUERY } from 'src/ui';

export const ImageMap = (props) => {
  const {
    id,
    src,
    maps = [],
    alt = 'Image with hotspots',
    className = '',
    onMapClick,
    // onMapEnter,
    onImageClick,
    onImageEnter,
    isEditor,
    isLazy,
  } = props;

  const Hotspot = isEditor ? EditorHotspot : ViewerHotspot;

  const handleMapClick = (map, index) => () => {
    onMapClick(map, index);
  };

  // const handleMapEnter = (map, index) => () => {
  //   onMapEnter(map, index);
  // };

  return (
    <Wrapper className={`sl-hotspot-container ${className}`}>
      <Img
        id={id}
        className='sl-hotspot-image'
        {...(isLazy ? { 'data-src': src } : { src })}
        alt={alt}
        draggable={false}
        {...(onImageClick ? { onClick: onImageClick } : {})}
        {...(onImageEnter ? { onMouseEnter: onImageEnter } : {})}
      />
      {maps.map((map, index) => {
        const { id, link, label, render, rotation, ...area } = map;
        return (
          <Hotspot
            key={id}
            id={id}
            className='sl-hotspot-area'
            href={link}
            target='_blank'
            rel='noopener nofollow'
            style={{
              ...area,
              ...(rotation ? { transform: `rotate(${rotation})` } : {}),
            }}
            aria-label={label || 'External link. Label was not provided.'}
            {...(onMapClick ? { onClick: handleMapClick(map, index) } : {})}
            // {...(onMapEnter
            //   ? { onMouseEnter: handleMapEnter(map, index) }
            //   : {})}
          >
            {/* {render && typeof render === 'function' && render(map, index)} */}
            <div>
              {isEditor && (
                <span
                  style={rotation ? { transform: `rotate(-${rotation})` } : {}}
                >
                  {index + 1}
                </span>
              )}
              {!!label && (
                <HotspotLabel
                  {...(Number(area.left.replace('%', '')) > 50
                    ? { style: { right: '100%', left: 'unset' } }
                    : {})}
                >
                  {label}
                </HotspotLabel>
              )}
            </div>
          </Hotspot>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;

  ${MEDIA_QUERY.sm} {
    /* apply styles to all siblings on hover (current, all next, all previous) */
    a:hover label,
    a:hover ~ a label,
    a:has(~ a:hover) label {
      transition: opacity 0.1s;
      opacity: 0;
    }
  }
`;

const Img = styled.img`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HotspotLabel = styled.label`
  position: absolute;
  top: -25px;
  left: 100%;
  width: auto;
  height: auto;
  white-space: nowrap;
  background-color: ${COLOR.black}80;
  color: ${COLOR.white};
  border: 0;
  border-radius: 4px;
  padding: 2px 5px;
  transform-origin: unset;
  pointer-events: none;

  ${MEDIA_QUERY.sm} {
    transition: opacity 0.3s 0.3s;
  }
`;

const HotspotBase = styled.a`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;

  & > div {
    position: relative;
  }
`;

const ViewerHotspot = styled(HotspotBase)`
  & > div {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    border: 5px solid ${COLOR.black}70;
    background-color: ${COLOR.white};
    transform-origin: center center;

    ${MEDIA_QUERY.sm} {
      transition: all 0.3s;
    }
  }

  ${MEDIA_QUERY.sm} {
    &:hover {
      & > div {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 2px solid ${COLOR.black}90;
        background-color: ${COLOR.black}40;
      }
    }
  }
`;

const EditorHotspot = styled(HotspotBase)`
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    border: 2px dotted ${COLOR.green};
    background-color: ${COLOR.black}80;
    color: ${COLOR.green};
    transform-origin: center center;

    ${MEDIA_QUERY.sm} {
      transition: all 0.3s;
    }
  }

  ${MEDIA_QUERY.sm} {
    &:hover {
      & > div {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 2px solid ${COLOR.blue};
      }
    }
  }
`;

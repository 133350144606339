import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useAppContext } from 'src/common/context';
import { Layout } from 'src/components/Layout';
import {
  Button,
  COLOR,
  GAP,
  MEDIA_QUERY,
  Paragraph,
  Subheading,
  WarningBox,
} from 'src/ui';
import { PAYPAL, USER_PLAN, VIEW_TYPES } from 'src/common/config';
import { apiCall, reportError } from 'src/common/utils';

const viewTypeLabels = VIEW_TYPES.map((e) => e.value);

export const PACKAGES_DETAILS = {
  starter: (
    <ul>
      <li>{USER_PLAN.starter.pages} customizable public webpage</li>
      <li>{USER_PLAN.starter.imagesPerPage} images per page</li>
      <li>{USER_PLAN.starter.hotspotsPerImage} hotspot links per image</li>
      {/* <li>&ndash;</li>
      <li>&ndash;</li> */}
      {/* <li>&ndash;</li> */}
      <li>
        {viewTypeLabels.length} view types ({viewTypeLabels.join(', ')})
      </li>
      <li>{USER_PLAN.starter.customLinks} link buttons per page</li>
      <li>No branding or watermarks</li>
      <li>Support</li>
      {/* <li>First week FREE</li> */}
    </ul>
  ),
  hosted: (
    <ul>
      <li>{USER_PLAN.hosted.pages} customizable public webpages</li>
      <li>{USER_PLAN.hosted.imagesPerPage} images per page</li>
      <li>{USER_PLAN.hosted.hotspotsPerImage} hotspot links per image</li>
      {/* <li>&ndash;</li>
      <li>&ndash;</li> */}
      {/* <li>&ndash;</li> */}
      <li>
        {viewTypeLabels.length} view types ({viewTypeLabels.join(', ')})
      </li>
      <li>{USER_PLAN.hosted.customLinks} link buttons per page</li>
      <li>No branding or watermarks</li>
      <li>Support</li>
      {/* <li>First week FREE</li> */}
    </ul>
  ),
  pro: (
    <ul>
      <li>{USER_PLAN.pro.pages} customizable public webpages</li>
      <li>{USER_PLAN.pro.imagesPerPage} images per page</li>
      <li>{USER_PLAN.pro.hotspotsPerImage} hotspot links per image</li>
      {/* <li>{USER_PLAN.pro.embeds} custom website embeds</li>
      <li>{USER_PLAN.pro.imagesPerEmbed} images per embed</li> */}
      {/* <li>
        {USER_PLAN.pro.embedRendersPerMonth.toLocaleString()} embed renders per
        month
      </li> */}
      <li>
        {viewTypeLabels.length} view types ({viewTypeLabels.join(', ')})
      </li>
      <li>{USER_PLAN.pro.customLinks} link buttons per page</li>
      <li>No branding or watermarks</li>
      <li>Priority support</li>
      {/* <li>First week FREE</li> */}
    </ul>
  ),
};

export const Subscription = () => {
  const navigate = useNavigate();
  const { user, getUserData, isLoading, setIsLoading } = useAppContext();
  const [subId, setSubId] = useState();
  const [isSubSetup, setIsSubSetup] = useState(false);
  const isNewUser = !user.subscription && !user.plan;
  const isSubscribedUser = !isNewUser && (user.subscription || {}).isActive;
  const currentPlanId = isSubscribedUser ? user.plan.id : null;
  const isPlanMatch = (planId) =>
    currentPlanId === planId &&
    user.plan &&
    user.plan.rate === USER_PLAN[planId].rate &&
    user.plan.pages === USER_PLAN[planId].pages &&
    user.plan.imagesPerPage === USER_PLAN[planId].imagesPerPage &&
    user.plan.hotspotsPerImage === USER_PLAN[planId].hotspotsPerImage;
  const isStarter = isPlanMatch(USER_PLAN.starter.id);
  const isHosted = isPlanMatch(USER_PLAN.hosted.id);
  const isPro = isPlanMatch(USER_PLAN.pro.id);
  const isPlanMatched = isStarter || isHosted || isPro;

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  const syncSubscription = (isCancel) => () => {
    setIsLoading(true);
    // clearDataCache().then(() => {
    alert('Finalizing your subscription... This could take up to a minute.');
    setTimeout(() => {
      if (isCancel) {
        alert('Your subscription is now canceled.');
      } else {
        setIsSubSetup(true);
      }
      getUserData();
    }, 30 * 1000);
    // });
  };

  const cancelSubscription = () => {
    const isConfirmed = confirm(
      'Are you sure you want to cancel your subscription?',
    );
    if (!isConfirmed) return;
    setIsLoading(true);
    apiCall({ query: 'cancelSub' })
      .then(() => {
        syncSubscription(true)();
      })
      .catch((error) => {
        reportError({ source: 'cancelSub', error });
        setIsLoading(false);
      });
  };

  const menu = isNewUser
    ? []
    : [
        ...(isSubscribedUser
          ? [
              <Button
                key='cancelSubscriptionButton'
                onClick={cancelSubscription}
                aria-label='Cancel your subscription'
                disabled={isLoading}
              >
                Cancel Subscription
              </Button>,
            ]
          : []),
        <Button
          key='goToProfileButton'
          onClick={goToProfile}
          aria-label='Go to profile'
          disabled={isLoading}
        >
          Profile
        </Button>,
        <Button
          key='goToDashboardButton'
          onClick={goToDashboard}
          aria-label='Go to dashabord'
          disabled={isLoading}
        >
          Dashboard
        </Button>,
      ];

  const currentPlanButton = (isMatch = false) => (
    <Button
      shadow
      disabled
      margin='30px 0 50px 0'
      style={isMatch ? {} : { opacity: 0, pointerEvents: 'none' }}
    >
      Current plan
    </Button>
  );

  const createSubscription = (plan) => (data, actions) => {
    // const planId = PAYPAL.plan[plan][isNewUser ? 'trialId' : 'id'];
    const planId = PAYPAL.planId[plan];
    return actions.subscription.create({
      custom_id: user.id,
      plan_id: planId,
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      },
    });
  };

  const onPayPalApprove = (data, actions) => {
    setSubId(data.subscriptionID);
    syncSubscription()();
  };

  const paypalProviderOptions = {
    'client-id': PAYPAL.clientId,
    currency: PAYPAL.currency,
    components: 'buttons',
    intent: 'subscription',
    vault: true,
  };

  const paypalButtonProps = {
    style: {
      label: 'subscribe',
      layout: 'vertical',
      color: 'black',
    },
    // fundingSource: 'card',
    onApprove: onPayPalApprove,
  };

  if (isSubSetup) {
    return (
      <Layout page={{ title: 'Subscription' }}>
        <Wrapper style={{ maxWidth: 400 }}>
          <Subheading>You're all set!</Subheading>
          <Paragraph>Subscription ID: {subId}</Paragraph>
          <Paragraph>
            Your subscription was setup successfully. You will soon be able to
            create interactive content with clickable hotspots and share them as
            webpages.
          </Paragraph>
          <Button primary big onClick={goToDashboard} disabled={isLoading}>
            Go to the dashboard
          </Button>
        </Wrapper>
      </Layout>
    );
  }

  return (
    <Layout
      page={{
        title: 'Subscription',
        menu,
        isLoading,
      }}
    >
      {isSubscribedUser && !isPlanMatched && (
        <Wrapper>
          <WarningBox style={{ maxWidth: 850, margin: '0 auto' }}>
            Your quota and/or rate differs from our current offerings!
            <br />
            <br />
            If you select one of the plans below, your quota and rate will match
            the selection, and this change will be irreversible.
          </WarningBox>
        </Wrapper>
      )}
      <PayPalScriptProvider options={paypalProviderOptions}>
        <WrapperFlex>
          <Plan isCurrent={isStarter}>
            <Subheading>{USER_PLAN.starter.name}</Subheading>
            {PACKAGES_DETAILS.starter}
            <Subheading>${USER_PLAN.starter.rate}/month</Subheading>
            <Em>Billed monthly in {PAYPAL.currency}</Em>
            {isSubscribedUser ? (
              currentPlanButton(isStarter)
            ) : (
              <PayPalButtons
                {...paypalButtonProps}
                createSubscription={createSubscription(USER_PLAN.starter.id)}
              />
            )}
          </Plan>
          <Plan isCurrent={isHosted}>
            <Subheading>{USER_PLAN.hosted.name}</Subheading>
            {PACKAGES_DETAILS.hosted}
            <Subheading>${USER_PLAN.hosted.rate}/month</Subheading>
            <Em>Billed monthly in {PAYPAL.currency}</Em>
            {isSubscribedUser ? (
              currentPlanButton(isHosted)
            ) : (
              <PayPalButtons
                {...paypalButtonProps}
                createSubscription={createSubscription(USER_PLAN.hosted.id)}
              />
            )}
          </Plan>
          <Plan isCurrent={isPro}>
            <Subheading>{USER_PLAN.pro.name}</Subheading>
            {PACKAGES_DETAILS.pro}
            <Subheading>${USER_PLAN.pro.rate}/month</Subheading>
            <Em>Billed monthly in {PAYPAL.currency}</Em>
            {isSubscribedUser ? (
              currentPlanButton(isPro)
            ) : (
              <PayPalButtons
                {...paypalButtonProps}
                createSubscription={createSubscription(USER_PLAN.pro.id)}
              />
            )}
          </Plan>
        </WrapperFlex>
      </PayPalScriptProvider>
    </Layout>
  );
};

const Em = styled.em`
  margin-top: -25px;
  margin-bottom: 30px;
  color: ${COLOR.darkGray};
  font-size: 11px;
`;

const Wrapper = styled.div`
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.sm};
  max-width: 1200px;
`;

const WrapperFlex = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${GAP.xl};

  ${MEDIA_QUERY.sm} {
    flex-direction: row;
  }
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: ${GAP.md};
  width: 350px;
  border: ${(props) => (props.isCurrent ? `2px solid ${COLOR.green}` : '0')};
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  ul {
    flex-grow: 1;
    margin: 0 0 ${GAP.md} 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    li {
      margin: 0 0 10px 0;
      padding: 0 10px 10px 10px;
      border-bottom: 1px solid #d7d7d7;

      ${MEDIA_QUERY.sm} {
        margin: 0 0 15px 0;
        padding: 0 10px 15px 10px;
      }

      &:last-child {
        margin-bottom: 0;
        /* ${(props) => (props.isNewUser ? '' : 'display: none;')}; */
      }
    }
  }
`;

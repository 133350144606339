import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SITE_TITLE } from 'src/common/config';
import { COLOR, TYPOGRAPHY, GAP } from 'src/ui';
import PageHeader from 'src/components/PageHeader';

const Header = ({ page, ...props }) => {
  return (
    <Wrapper {...props}>
      {!(page && page.logo === false) && (
        <GlobalHeader>
          <Title to='/?utm=nav'>{SITE_TITLE}</Title>
        </GlobalHeader>
      )}
      {page && (page.title || (page.menu && page.menu.length > 0)) && (
        <PageHeader {...page} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  z-index: 999;
`;

const GlobalHeader = styled.div`
  margin: 0;
  padding: 10px ${GAP.sm};
  width: 100vw;
  background-color: ${COLOR.black};
`;

const Title = styled(Link)`
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: ${TYPOGRAPHY.size.sm};
  color: ${COLOR.green};
  text-shadow: 0 1px 2px #000, 1px 0 2px #000, 1px 1px 2px #000;
  text-decoration: none;
  cursor: pointer;
`;

export default Header;

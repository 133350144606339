import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'src/components/Layout';
import {
  Button,
  Checkbox,
  COLOR,
  Flex,
  GAP,
  MEDIA_QUERY,
  Subheading,
} from 'src/ui';
import { PAYPAL, SIGNUP_URL, USER_PLAN } from 'src/common/config';
import { PACKAGES_DETAILS } from 'src/pages/Subscription';

export const Start = () => {
  const navigate = useNavigate();
  const [canStart, setCanStart] = useState(false);
  const PLANS = [USER_PLAN.starter, USER_PLAN.hosted, USER_PLAN.pro];

  const ctaClick = () => {
    window.location = SIGNUP_URL;
  };

  const backToHomepage = () => {
    navigate('/?utm=nav');
  };

  const handleCheckbox = (e) => {
    setCanStart(e.target.checked);
  };

  const menu = [
    <Button
      key='backToHomepageButton'
      onClick={backToHomepage}
      aria-label='Go to the homepage'
    >
      Homepage
    </Button>,
  ];

  return (
    <Layout page={{ title: 'Get Started', menu }}>
      <WrapperFlex>
        {PLANS.map((e, i) => {
          // const saleRate = e.rate + (i + 1) * 10;
          // const percentOff = (100 - (e.rate * 100) / saleRate).toFixed(0);
          return (
            <Plan key={e.id}>
              <Subheading>{e.name}</Subheading>
              {PACKAGES_DETAILS[e.id]}
              {/* <Subheading>{percentOff}% off</Subheading> */}
              <Subheading>
                <s>
                  $
                  {
                    {
                      0: 20,
                      1: 40,
                      2: 80,
                    }[i]
                  }
                  /month
                </s>
              </Subheading>
              <Subheading>
                <span>${e.rate}/month</span>
              </Subheading>
              <Em>Billed monthly in {PAYPAL.currency} *</Em>
            </Plan>
          );
        })}
      </WrapperFlex>
      <Flex justifyContent='center' margin={`${GAP.xl} 0 ${GAP.md} 0`}>
        <Checkbox
          label={[
            'I have read and agree to the ',
            <Link key='start-checkbox' to='/privacy'>
              privacy and terms
            </Link>,
          ]}
          onChange={handleCheckbox}
        />
      </Flex>
      <Flex justifyContent='center' margin={`0 0 ${GAP.xxl} 0`}>
        <Button shadow primary big onClick={ctaClick} disabled={!canStart}>
          Signup for an account
        </Button>
      </Flex>
      <Wrapper>
        <Em>* Setup billing after signup. Cancel anytime.</Em>
        <PayPalImg
          src='https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/Full_Online_Tray_RGB.png'
          alt='PayPal Acceptance Mark'
        />
      </Wrapper>
    </Layout>
  );
};

const Em = styled.em`
  display: block;
  color: ${COLOR.darkGray};
  font-size: 11px;
  text-align: center;
`;

const Wrapper = styled.div`
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.sm};
  max-width: 1200px;
`;

const WrapperFlex = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${GAP.xl};

  ${MEDIA_QUERY.sm} {
    flex-direction: row;
  }
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: ${GAP.md};
  width: 350px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  h2 s {
    color: red;
  }

  h2 span {
    color: green;
  }

  ul {
    flex-grow: 1;
    margin: ${GAP.sm} 0 ${GAP.md} 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    li {
      margin: 0 0 10px 0;
      padding: 0 10px 10px 10px;
      border-bottom: 1px solid #d7d7d7;
      font-size: 16px;

      ${MEDIA_QUERY.sm} {
        margin: 0 0 15px 0;
        padding: 0 10px 15px 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const PayPalImg = styled.img`
  display: block;
  margin: ${GAP.sm} auto 0 auto;
  width: 150px;
  border: 0;
`;
